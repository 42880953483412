import React, { useState, useEffect, memo, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import "./TarotRoom.css";
import { tarotCards } from "../assets/tarotCards"; // Asegúrate de que este archivo contenga las cartas
import QuickTutorialModal from "../components/QuickTutorialModal";
import BuyMystiCoinsModal from "../components/BuyMystiCoinsModal";
import {
  FaArrowLeft,
  FaQuestionCircle,
  FaStarOfLife,
  FaGem,
} from "react-icons/fa";
import DOMPurify from "dompurify";
import Loader from "../components/Loader";
import { useAuth } from "../contexts/AuthContext"; // Importa el AuthContext correctamente
import { useMysticoins } from "../contexts/MysticoinsContext"; // Importa el hook de Mysticoins
import { getAuth } from "firebase/auth"; // Importa Firebase Auth para obtener el token
import StarryBackground from "../components/StarryBackground.js";

const TarotRoom = () => {
  const [question, setQuestion] = useState("");
  const [reading, setReading] = useState(null);
  const [selectedSpread, setSelectedSpread] = useState("three_card");
  const [showSpreadHint, setShowSpreadHint] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);
  const [showInterpretation, setShowInterpretation] = useState(false);
  const [showSpreadInfo, setShowSpreadInfo] = useState(false);
  const [isTutorialOpen, setIsTutorialOpen] = useState(false);
  const {
    updateMysticoinsRemaining,
    fetchMysticoinsRemaining,
    mysticoinsRemaining,
  } = useMysticoins();
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const cardRef = useRef(null);
  const spreadInfoRef = useRef(null);
  const imgRefs = useRef([]); // Referencia para las imágenes
  const { isAuthenticated, updateAuthState } = useAuth(); // Variables de autenticación
  const navigate = useNavigate(); // Para redirigir al usuario

  useEffect(() => {
    // Mostrar el hint después de un breve retraso
    const showTimer = setTimeout(() => setShowSpreadHint(true), 2000);
    // Ocultar el hint después de 5 segundos
    const hideTimer = setTimeout(() => setShowSpreadHint(false), 7000);
    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
    };
  }, []);

  const getSpreadDisplayText = () => {
    if (showSpreadHint) {
      return "↓ Try different spreads! ↓";
    }
    return (
      spreads.find((s) => s.value === selectedSpread)?.name || "Select a spread"
    );
  };

  const goToOrdinals = () => {
    navigate("/ordinals");
  };

  // Comprobar si el usuario está autenticado y redirigir si no lo está
  useEffect(() => {
    if (!isAuthenticated) {
      updateAuthState({ isAuthenticated: false });
      navigate("/signin"); // Redirige al login si no está autenticado
    }
  }, [isAuthenticated, updateAuthState, navigate]);

  // Agregar spreads
  const spreads = [
    {
      value: "single_card",
      name: "Single Card",
      description: "Quick and focused insight.",
    },
    {
      value: "three_card",
      name: "Three Cards",
      description: "Past, Present, Future",
    },
    {
      value: "celtic_cross",
      name: "Celtic Cross",
      description: "In-depth and detailed reading",
    },
    {
      value: "horseshoe",
      name: "Horseshoe",
      description: "Seven aspects of a situation",
    },
    {
      value: "relationship",
      name: "Relationship",
      description: "Dynamics between two people",
    },
    {
      value: "career_path",
      name: "Career Path",
      description: "Guidance for your career",
    },
    {
      value: "spiritual_guidance",
      name: "Spiritual Guidance",
      description: "Inner illumination",
    },
    {
      value: "monthly_forecast",
      name: "Monthly Forecast",
      description: "Overview for the month",
    },
    {
      value: "decision_making",
      name: "Decision Making",
      description: "Pros and cons",
    },
    {
      value: "chakra_alignment",
      name: "Chakra Alignment",
      description: "Energy balance",
    },
  ];

  // Tutorial steps
  const tutorialSteps = [
    {
      title: "Welcome to the Tarot Room",
      description:
        "Unlock deep tarot readings and gain valuable insights for your most important questions.",
    },
    {
      title: "Choose Your Spread",
      description:
        "Select from 10 unique spreads, each designed to align perfectly with your question or situation.",
    },
    {
      title: "Ask Your Question",
      description:
        "Type your question or focus in the input field. More details lead to a more accurate reading.",
    },
    {
      title: "Receive Your Reading",
      description:
        "Our powerful AI will select and interpret the cards for your chosen spread.",
    },
    {
      title: "Using MystiCoins",
      description:
        "Each reading costs MystiCoins. Check your balance at the top of the screen.",
    },
    {
      title: "Get More MystiCoins",
      description:
        "Running low? Purchase more MystiCoins to continue your readings.",
    },
  ];

  const handleDeposit = async (amount) => {
    try {
      await fetch(process.env.REACT_APP_DEPOSIT_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ amount }),
        credentials: "include",
      });

      updateMysticoinsRemaining(); // Actualiza Mysticoins después del depósito
      setIsBuyModalOpen(false);
    } catch (error) {}
  };

  useEffect(() => {
    // Ejecuta el fetch solo al montar el componente (navegar a TarotRoom)
    fetchMysticoinsRemaining();

    return () => {
      // Actualiza Mysticoins también al desmontar
      updateMysticoinsRemaining();
    };
  }, [fetchMysticoinsRemaining, updateMysticoinsRemaining]); // Se llama solo una vez al montar el componente

  // Función de animación de cartas
  const animateCardSelection = (cards) => {
    cards.forEach((card, index) => {
      setTimeout(() => {
        setSelectedCards((prev) => [...prev, card]);
        if (index === cards.length - 1) {
          setTimeout(() => setShowInterpretation(true), 1000);
        }
      }, index * 500);
    });
  };

  const sanitizeInput = (input) => {
    return DOMPurify.sanitize(input, {
      ALLOWED_TAGS: [],
      ALLOWED_ATTR: [],
    })
      .replace(/[<>]/g, "")
      .trim();
  };

  const validateInput = (input) => {
    if (input.length === 0) {
      throw new Error("Input cannot be empty");
    }
    if (input.length < 3) {
      throw new Error("Input is too short");
    }
    if (input.length > 1000) {
      throw new Error("Input is too long");
    }

    if (!/[a-zA-Z0-9]/.test(input)) {
      throw new Error("Input must contain letters or numbers");
    }

    return input;
  };

  // Manejar el envío del formulario y la lectura de Tarot
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    try {
      if (!isAuthenticated) {
        throw new Error("No estás autenticado. Inicia sesión para continuar.");
      }

      const sanitizedQuestion = sanitizeInput(question);
      const validatedQuestion = validateInput(sanitizedQuestion);

      if (mysticoinsRemaining > 0) {
        setIsLoading(true);
        setSelectedCards([]);
        setShowInterpretation(false);

        try {
          const auth = getAuth(); // Obtener la instancia de auth desde Firebase
          const currentUser = auth.currentUser;
          const token = await currentUser.getIdToken(true); // Forzar la actualización del token

          const response = await fetch(
            process.env.REACT_APP_TAROT_READING_URL,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`, // Pasar el token en los headers
              },
              body: JSON.stringify({
                question: validatedQuestion,
                spread_type: selectedSpread,
              }),
            }
          );

          if (response.ok) {
            const data = await response.json();
            setReading(data);
            animateCardSelection(data.cards);
            updateMysticoinsRemaining();
          } else if (response.status === 403) {
            setIsBuyModalOpen(true);
          } else if (response.status === 401) {
            sessionStorage.removeItem("access_token");
            setErrorMessage(
              "Sesión expirada. Por favor, vuelve a iniciar sesión."
            );
            updateAuthState({ isAuthenticated: false });
            navigate("/signin");
          } else {
            const errorData = await response.json();
            setErrorMessage(
              errorData.error ||
                "Error en la lectura del Tarot. Inténtalo de nuevo."
            );
          }
        } catch (error) {
          setErrorMessage("Error en la lectura del Tarot. Inténtalo de nuevo.");
        }

        setIsLoading(false);
      } else {
        setIsBuyModalOpen(true);
      }
    } catch (error) {
      setErrorMessage(
        error.message ||
          "Entrada inválida. Por favor, ingresa una pregunta válida."
      );
    }

    setQuestion("");
  };

  // Función de reinicio de lectura
  const resetReading = () => {
    setQuestion("");
    setReading(null);
    setSelectedCards([]);
    setShowInterpretation(false);
    setErrorMessage("");
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target;
            img.src = img.dataset.src;
            img.classList.add("visible");
            observer.unobserve(img);
          }
        });
      },
      { threshold: 0.1 }
    );

    imgRefs.current.forEach((img) => {
      if (img) observer.observe(img);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="tarot-room">
      <StarryBackground />
      {isLoading && <Loader />}

      <header className="tarot-header">
        <Link to="/dashboard" className="back-link">
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <FaArrowLeft />
          </motion.div>
        </Link>
        <div className="tarot-title-container">
          <motion.h1 className="tarot-title">Tarot Room</motion.h1>
          <div className="mysticoins-counter">
            MystiCoins:{" "}
            {mysticoinsRemaining !== null ? mysticoinsRemaining : "Loading.."}
          </div>
        </div>
        <div className="header-buttons">
          <motion.button
            className="nft-button"
            onClick={goToOrdinals}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaGem />
          </motion.button>
          <motion.button
            className="tarot-tutorial-button"
            onClick={() => setIsTutorialOpen(true)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaQuestionCircle />
          </motion.button>
        </div>
      </header>

      <AnimatePresence mode="wait">
        {!reading && !isLoading && (
          <motion.div
            className="tarot-form glassmorphism"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
          >
            <div className="spread-select-container" ref={spreadInfoRef}>
              <select
                value={selectedSpread}
                onChange={(e) => setSelectedSpread(e.target.value)}
                className={`spread-select ${showSpreadHint ? "hint" : ""}`}
              >
                <option value="" disabled hidden>
                  {getSpreadDisplayText()}
                </option>
                {spreads.map((spread) => (
                  <option key={spread.value} value={spread.value}>
                    {spread.name}
                  </option>
                ))}
              </select>
              <FaQuestionCircle
                className="spread-info-icon"
                onClick={() => setShowSpreadInfo(!showSpreadInfo)}
              />
              {showSpreadInfo && (
                <motion.div
                  className="spread-info glassmorphism"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                >
                  {spreads.find((s) => s.value === selectedSpread).description}
                </motion.div>
              )}
            </div>
            <input
              type="text"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Whisper your question to the cards..."
              className="question-input"
            />
            <motion.button
              onClick={handleSubmit}
              className="submit-button"
              disabled={isLoading}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {isLoading ? (
                <FaStarOfLife className="loading-icon" />
              ) : (
                "Reveal the Cards"
              )}
            </motion.button>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </motion.div>
        )}

        {reading && !isLoading && (
          <motion.div
            className="reading-container glassmorphism"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.5 }}
          >
            <div className="cards-container">
              <AnimatePresence>
                {selectedCards.map((card, index) => (
                  <motion.div
                    key={index}
                    className="card"
                    initial={{ opacity: 0, rotateY: 180, scale: 0.5 }}
                    animate={{ opacity: 1, rotateY: 0, scale: 1 }}
                    exit={{ opacity: 0, rotateY: 180, scale: 0.5 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src={tarotCards[card.toLowerCase().replace(/ /g, "_")]}
                      alt={card}
                      ref={cardRef}
                    />

                    <motion.p
                      className="card-name"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.5 }}
                    >
                      {card}
                    </motion.p>
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>

            {showInterpretation && (
              <motion.div
                className="interpretation glassmorphism"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
              >
                <ReactMarkdown
                  className="bot-response-text"
                  components={{
                    p: ({ node, ...props }) => (
                      <p className="bot-response-paragraph" {...props} />
                    ),
                  }}
                >
                  {reading.interpretation}
                </ReactMarkdown>
                <motion.button
                  onClick={resetReading}
                  className="reset-button"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Make Another Read
                </motion.button>
              </motion.div>
            )}
          </motion.div>
        )}
      </AnimatePresence>

      <QuickTutorialModal
        isOpen={isTutorialOpen}
        onClose={() => setIsTutorialOpen(false)}
        tutorialSteps={tutorialSteps}
      />

      <BuyMystiCoinsModal
        isOpen={isBuyModalOpen}
        onClose={() => setIsBuyModalOpen(false)}
        onDeposit={handleDeposit}
      />
    </div>
  );
};

export default memo(TarotRoom);
