// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    isLoading: true,
    error: null,
    user: null,
  });

  const navigate = useNavigate();

  // Función para actualizar el estado de autenticación
  const updateAuthState = useCallback((updates) => {
    setAuthState((prev) => {
      const newState = { ...prev, ...updates };
      return newState;
    });
  }, []);

  // Función para manejar el login exitoso
  const handleLoginSuccess = useCallback((user) => {
    updateAuthState({ isAuthenticated: true, isLoading: false, error: null, user });
    // No es necesario almacenar el token en sessionStorage
  }, [updateAuthState]);

  // Función para manejar el logout
  const handleLogout = useCallback(async () => {
    try {
      await auth.signOut();
      sessionStorage.clear();
      localStorage.clear();
      updateAuthState({ isAuthenticated: false, isLoading: false, user: null });
      navigate('/'); // Redirigir al usuario a la página de inicio
    } catch (error) {
      
      updateAuthState({ isAuthenticated: true, isLoading: false, error: "Failed to log out" });
    }
  }, [updateAuthState, navigate]);

  // Función para verificar el estado de autenticación
  const verifyAuthState = useCallback(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        handleLoginSuccess(user);  // Actualizar el estado de autenticación
      } else {
        updateAuthState({ isAuthenticated: false, isLoading: false, error: null, user: null });
        // Eliminamos la redirección automática a '/'
      }
    });
    return () => unsubscribe();
  }, [handleLoginSuccess, updateAuthState]);

  // Verificar el estado de autenticación al montar el componente
  useEffect(() => {
    const unsubscribe = verifyAuthState();
    return unsubscribe;  // Cleanup al desmontar el componente
  }, [verifyAuthState]);

  // Memoizar el valor del contexto para optimizar el rendimiento
  const contextValue = useMemo(() => ({
    ...authState,
    updateAuthState,
    handleLoginSuccess,
    handleLogout,
  }), [authState, updateAuthState, handleLoginSuccess, handleLogout]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook personalizado para usar el contexto de autenticación
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth debe ser usado dentro de un AuthProvider');
  }
  return context;
};





