import React, { useEffect, useState } from 'react';
import './StarryBackground.css';

const StarryBackground = () => {
  const [stars, setStars] = useState([]);

  useEffect(() => {
    const generateStars = () => {
      const starCount = 500; // Ajusta este número para más o menos estrellas
      const newStars = [];
      for (let i = 0; i < starCount; i++) {
        newStars.push({
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          animationDuration: `${Math.random() * 3 + 1}s`,
          size: `${Math.random() * 1 + 0}px`
        });
      }
      setStars(newStars);
    };

    generateStars();
    window.addEventListener('resize', generateStars);

    return () => {
      window.removeEventListener('resize', generateStars);
    };
  }, []);

  return (
    <div className="starry-background">
      {stars.map((star, index) => (
        <div
          key={index}
          className="star"
          style={{
            left: star.left,
            top: star.top,
            width: star.size,
            height: star.size,
            animationDuration: star.animationDuration
          }}
        />
      ))}
    </div>
  );
};

export default StarryBackground;