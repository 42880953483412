import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  FaBitcoin,
  FaExternalLinkAlt,
  FaArrowRight,
  FaGem,
  FaRandom,
  FaCrown,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { tarotCards } from "../assets/tarotCards";
import gammaLogo from "../assets/images/gamma3.png";
import bittechLogo from "../assets/images/Bittechlogo.svg";
import skullLogo from "../assets/images/Skull.svg";
import StarryBackground from "../components/StarryBackground.js";

import "./Ordinals.css";

const Ordinals = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const navigate = useNavigate();

  const cardNames = [
    "The Fool",
    "The Magician",
    "The High Priestess",
    "The Empress",
    "The Emperor",
    "The Hierophant",
    "The Lovers",
    "The Chariot",
    "Strength",
    "The Hermit",
    "Wheel of Fortune",
    "Justice",
    "The Hanged Man",
    "Death",
    "Temperance",
    "The Devil",
    "The Tower",
    "The Star",
    "The Moon",
    "The Sun",
    "Judgment",
    "The World",
  ];

  const handleCardClick = (cardName) => {
    setSelectedCard(cardName);
  };

  const closeModal = () => {
    setSelectedCard(null);
  };

  const goToTarotRoom = () => {
    navigate("/tarot-room");
  };

  const goToGamma = () => {
    window.open(
      "https://gamma.io/ordinals/collections/mystichat-ai-tarot-neon-arcana",
      "_blank"
    );
  };

  // Definimos las variantes de animación
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1, // Ajusta el tiempo entre cartas
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 }, // Las cartas comienzan desplazadas hacia abajo y transparentes
    visible: { opacity: 1, y: 0 }, // Terminan en su posición y totalmente opacas
  };

  return (
    <div className="ordinals-container">
      <StarryBackground />
      <h1>MystiChat AI Tarot Neon Arcana</h1>
      <div className="ordinals-description">
        <p>
          We proudly present an ultra-exclusive collection of 22 Major Arcana
          Tarot cards, masterfully crafted and now immortalized on the Bitcoin
          blockchain through Ordinals NFTs. These aren't just digital
          collectibles—they're the original masterpieces powering our
          groundbreaking MystiChat AI web app.
        </p>
        <p>
          We're not just building on Bitcoin; we're pioneering the future with
          it. Join us as we push the boundaries of art and technology, forging a
          new path on the unshakable foundation of Bitcoin. Be part of the
          movement that's redefining what's possible.
        </p>
        <p>Jump now into the MystiChat metaverse!</p>
        <a
          href="https://gamma.io/ordinals/collections/mystichat-ai-tarot-neon-arcana"
          target="_blank"
          rel="noopener noreferrer"
          className="gamma-link"
        >
          View on Gamma <FaExternalLinkAlt />
        </a>
      </div>

      {/* Aplicamos las variantes al contenedor de las cartas */}
      <motion.div
        className="card-grid"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {cardNames.map((cardName, index) => {
          const cardKey = cardName.toLowerCase().replace(/ /g, "_");
          return (
            <motion.div
              key={cardKey}
              className="card-item"
              variants={cardVariants} // Aplicamos las variantes a cada carta
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => handleCardClick(cardName)}
            >
              <img src={tarotCards[cardKey]} alt={cardName} />
              <p>{cardName}</p>
            </motion.div>
          );
        })}
      </motion.div>

      {selectedCard && (
        <motion.div
          className="card-modal-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="card-modal"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
          >
            <button className="close-modal" onClick={closeModal}>
              &times;
            </button>
            <img
              src={tarotCards[selectedCard.toLowerCase().replace(/ /g, "_")]}
              alt={selectedCard}
              className="modal-card-image"
            />
            <h2>{selectedCard}</h2>
            <p className="card-description">
              The mystical energy of {selectedCard} calls to you. Will fate align
              to make this your digital talisman?
            </p>
            <p className="mint-description">
              Embrace the unknown! Each mint is a journey into mystery.
              Your destined card awaits revelation upon minting.
            </p>
            <button className="buy-button" onClick={goToGamma}>
              <FaBitcoin /> Mint Your Mystery Card
            </button>
            <p className="collection-hint">
              For the true connoisseur: Mint the full collection and possess all 22
              Major Arcana!
            </p>
            <button className="tarot-room-button" onClick={goToTarotRoom}>
              Explore Tarot Room <FaArrowRight />
            </button>
          </motion.div>
        </motion.div>
      )}

      <div className="minting-info">
        <h2>How It Works</h2>
        <div className="info-cards">
          <div className="info-card">
            <FaGem className="info-icon" />
            <h3>Mint Your Card</h3>
            <p>
              Choose to mint a single card or the entire collection of 22 cards.
            </p>
          </div>
          <div className="info-card">
            <FaBitcoin className="info-icon" />
            <h3>Price Options</h3>
            <p>
              Individual cards: 0.0454 BTC
              <br />
              Full collection: 1 BTC
            </p>
          </div>
          <div className="info-card">
            <FaRandom className="info-icon" />
            <h3>Random Selection</h3>
            <p>Receive a randomly selected card from the 22 Major Arcana.</p>
          </div>
          <div className="info-card">
            <FaCrown className="info-icon" />
            <h3>Exclusivity</h3>
            <p>
              Each card is unique and connected to the MystiChat Tarot experience.
            </p>
          </div>
        </div>
        <button className="buy-button full-collection" onClick={goToGamma}>
          <FaBitcoin /> Mint Full Collection on Gamma
        </button>
        <button className="tarot-room-button" onClick={goToTarotRoom}>
          Back to Tarot Room <FaArrowRight />
        </button>
      </div>

      <footer className="gamma-footer">
        <div className="footer-left">
          <img src={skullLogo} alt="MystiChat Skull" className="skull-logo" />
          <span className="mystichat-text">MystiChat AI</span>
        </div>
        <div className="footer-center">
          <img src={gammaLogo} alt="Gamma Logo" className="gamma-logo" />
        </div>
        <div className="footer-right">
          <a
            href="https://www.bittechnetwork.com"
            target="_blank"
            rel="noopener noreferrer"
            className="bittech-link"
          >
            <img src={bittechLogo} alt="Bittech Logo" className="bittech-logo" />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Ordinals;
