import React from 'react';
import './LegalDocuments.css';

const PrivacyPolicy = () => {
  return (
    <div className="legal-container">
      <h1 className="legal-heading">BitTech Network - MystiChat Privacy Policy</h1>

      <p className="legal-date">Last Updated: [30/8/24]</p>

      <p className="legal-paragraph">This Privacy Policy describes how BitTech Network ("we", "our", or "us") collects, uses, and discloses your personal information when you use our MystiChat application ("MystiChat" or the "Service"). As a brand that embraces DeFi (Decentralized Finance), our approach to privacy is influenced by the principles of decentralization and blockchain technology.</p>

      <h2 className="legal-subheading">1. Information We Collect</h2>
      <p className="legal-paragraph">We collect several types of information from and about users of our Service, including:</p>
      <ul className="legal-list">
        <li className="legal-list-item">Personal Data: Email address, first name and last name, profile picture</li>
        <li className="legal-list-item">Usage Data: IP address, browser type and version, pages visited, time and date of visit</li>
        <li className="legal-list-item">Payment and Transaction Data: Cryptocurrency wallet addresses, transaction hashes, amount of cryptocurrency sent, date and time of transaction, MystiCoins balance and transactions</li>
        <li className="legal-list-item">Blockchain Data: Public blockchain addresses and transaction data related to your interactions with our DeFi services</li>
      </ul>

      <h2 className="legal-subheading">2. How We Use Your Information</h2>
      <p className="legal-paragraph">We use the information we collect or receive:</p>
      <ul className="legal-list">
        <li className="legal-list-item">To provide and maintain our Service</li>
        <li className="legal-list-item">To manage your account and provide you with customer support</li>
        <li className="legal-list-item">To process payments, manage MystiCoins transactions, and prevent fraud</li>
        <li className="legal-list-item">To notify you about changes to our Service</li>
        <li className="legal-list-item">To monitor and analyze usage patterns and trends</li>
        <li className="legal-list-item">To facilitate and record blockchain transactions in line with our DeFi operations</li>
      </ul>

      <h2 className="legal-subheading">3. Disclosure of Your Information</h2>
      <p className="legal-paragraph">We may share your personal information in the following situations:</p>
      <ul className="legal-list">
        <li className="legal-list-item">With Service Providers: We may share your information with third-party vendors, service providers, contractors or agents who perform services for us or on our behalf.</li>
        <li className="legal-list-item">For Business Transfers: We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
        <li className="legal-list-item">With Your Consent: We may disclose your personal information for any other purpose with your consent.</li>
        <li className="legal-list-item">On the Blockchain: Due to the nature of blockchain technology, certain transaction data may be publicly visible on the blockchain.</li>
      </ul>

      <h2 className="legal-subheading">4. Third-Party Service Providers</h2>
      <p className="legal-paragraph">4.1. We use Google for authentication purposes. Your use of Google authentication is subject to Google's Privacy Policy.</p>
      <p className="legal-paragraph">4.2. We use OpenAI and other AI service providers for our AI functionalities. These providers may collect and store the content of your interactions with MystiChat's AI features.</p>
      <p className="legal-paragraph">4.3. We use NOWPayments for processing cryptocurrency payments. Your use of our payment services is subject to NOWPayments' Privacy Policy.</p>
      <p className="legal-paragraph">4.4. We utilize various blockchain networks for our DeFi operations. The data processed on these networks is subject to the inherent transparency of blockchain technology.</p>

      <h2 className="legal-subheading">5. MystiCoins and Payment Information</h2>
      <p className="legal-paragraph">5.1. We collect and store information related to your MystiCoins balance and transactions. This includes the number of MystiCoins purchased, used, and remaining in your account.</p>
      <p className="legal-paragraph">5.2. While we do not store your full cryptocurrency wallet information, we do keep records of transaction hashes and amounts for accounting and customer support purposes.</p>
      <p className="legal-paragraph">5.3. Information about your MystiCoins usage helps us improve our services and may be used for internal analytics and service optimization.</p>

      <h2 className="legal-subheading">6. DeFi and Blockchain Data</h2>
      <p className="legal-paragraph">6.1. As a DeFi platform, certain data related to your transactions and interactions with our smart contracts will be recorded on public blockchains.</p>
      <p className="legal-paragraph">6.2. While blockchain data is pseudonymous, it is also public and immutable. Users should be aware that blockchain analysts may be able to derive information from public blockchain data.</p>
      <p className="legal-paragraph">6.3. We do not have the ability to alter or delete information stored on the blockchain.</p>

      <h2 className="legal-subheading">7. Data Retention</h2>
      <p className="legal-paragraph">We retain your personal information, including MystiCoins transaction history, only for as long as necessary to provide you with our services and as described in this Privacy Policy. You can request deletion of your account data at any time, subject to legal and operational requirements. Please note that data recorded on public blockchains as part of our DeFi operations cannot be deleted due to the nature of blockchain technology.</p>

      <h2 className="legal-subheading">8. Security of Your Personal Information</h2>
      <p className="legal-paragraph">The security of your personal information is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. We use industry-standard practices to protect your data, including encryption of sensitive information. However, the security of information on the blockchain is dependent on the security of the blockchain network itself and your own key management practices.</p>

      <h2 className="legal-subheading">9. Your Data Protection Rights</h2>
      <p className="legal-paragraph">Depending on your location, you may have certain rights regarding your personal information, such as the right to access, update or delete the information we have on you. This includes information about your MystiCoins balance and transaction history. However, please be aware that due to the nature of blockchain technology, we may not be able to delete or modify data that has been recorded on a public blockchain.</p>

      <h2 className="legal-subheading">10. Children's Privacy</h2>
      <p className="legal-paragraph">MystiChat is not intended for children under the age of 18. We do not knowingly collect personal information from children under 18.</p>

      <h2 className="legal-subheading">11. Changes to This Privacy Policy</h2>
      <p className="legal-paragraph">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date at the top of this Privacy Policy.</p>

      <div className="legal-contact">
        <h3>Contact Us</h3>
        <p>If you have any questions about this Privacy Policy, please contact us:</p>
        <p>By email: networkbittech@gmail.com</p>
        <p>By visiting this page on our website: www.bittechnetwork.com</p>
      </div>

      <p className="legal-footer">BY USING MYSTICHAT, YOU ACKNOWLEDGE THAT YOU HAVE READ THIS PRIVACY POLICY, UNDERSTAND IT, AND AGREE TO BE BOUND BY ITS TERMS.</p>
    </div>
  );
};

export default PrivacyPolicy;