import React, { createContext, useState, useEffect, useCallback, useContext, useRef } from 'react';
import { useAuth } from './AuthContext';
import { auth } from '../firebase'; // Importa Firebase auth

export const MysticoinsContext = createContext();

export const MysticoinsProvider = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const [mysticoinsRemaining, setMysticoinsRemaining] = useState(0);
  const [mysticLevel, setMysticLevel] = useState('Mystic Novice');

  // Usamos useRef para controlar si ya hemos hecho el fetch
  const hasFetchedRef = useRef(false);

  // Función para obtener MystiCoins desde el backend
  const fetchMysticoinsRemaining = useCallback(async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        
        return;
      }

      // Obtiene el token de ID actual
      const idToken = await currentUser.getIdToken();

      const response = await fetch(process.env.REACT_APP_USER_STATUS_URL, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setMysticoinsRemaining(data.mysticoins);
      setMysticLevel(data.mystic_level || 'Mystic Novice');
    } catch (error) {
      
    }
  }, []);

  // useEffect que controla la ejecución del fetch al montar el componente o cuando el usuario cambia
  useEffect(() => {
    if (isAuthenticated && user && !hasFetchedRef.current) {
      fetchMysticoinsRemaining();
      hasFetchedRef.current = true;
    }
  }, [isAuthenticated, user, fetchMysticoinsRemaining]);

  const updateMysticoinsRemaining = useCallback(() => {
    fetchMysticoinsRemaining();
  }, [fetchMysticoinsRemaining]);

  const clearMysticoinsRemaining = useCallback(() => {
    setMysticoinsRemaining(0);
    setMysticLevel('Mystic Novice');
    hasFetchedRef.current = false;
  }, []);

  return (
    <MysticoinsContext.Provider
      value={{
        mysticoinsRemaining,
        mysticLevel,
        fetchMysticoinsRemaining,
        updateMysticoinsRemaining,
        clearMysticoinsRemaining
      }}
    >
      {children}
    </MysticoinsContext.Provider>
  );
};

// Hook para usar el contexto de Mysticoins
export const useMysticoins = () => {
  const context = useContext(MysticoinsContext);
  if (!context) {
    throw new Error('useMysticoins debe ser usado dentro de un MysticoinsProvider');
  }
  return context;
};






