import React, { useState, useEffect, memo, useRef } from "react";
import { motion } from "framer-motion";
import DOMPurify from "dompurify";
import { Link, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import QuickTutorialModal from "../components/QuickTutorialModal.js";
import BuyMystiCoinsModal from "../components/BuyMystiCoinsModal";
import Loader from "../components/Loader";
import MusicPlayer from "../components/MusicPlayer";
import {
  FaArrowLeft,
  FaCopy,
  FaDownload,
  FaPaperPlane,
  FaQuestionCircle,
  FaTrash,
} from "react-icons/fa";
import "./DreamRoom.css";
import { useMysticoins } from "../contexts/MysticoinsContext";
import StarryBackground from "../components/StarryBackground.js";
import { getAuth } from "firebase/auth"; // Importa Firebase Auth

const DreamRoom = () => {
  const [response, setResponse] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [isTutorialOpen, setIsTutorialOpen] = useState(false);
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [currentBackground, setCurrentBackground] = useState(null);
  const [previousBackground, setPreviousBackground] = useState(null);
  const musicPlayerRef = useRef(null);
  const navigate = useNavigate();

  const {
    updateMysticoinsRemaining,
    fetchMysticoinsRemaining,
    mysticoinsRemaining,
  } = useMysticoins();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        setErrorMessage("Por favor, inicia sesión para acceder al Dream Room.");
        navigate("/login"); // Redirige al usuario al inicio de sesión
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (backgroundImage) {
      setPreviousBackground(currentBackground);
      setCurrentBackground(backgroundImage);
    }
  }, [backgroundImage, currentBackground]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchMysticoinsRemaining();
    }

    return () => {
      isMounted = false;
      updateMysticoinsRemaining();
    };
  }, [fetchMysticoinsRemaining, updateMysticoinsRemaining]);

  const sanitizeInput = (input) => {
    return DOMPurify.sanitize(input, {
      ALLOWED_TAGS: [],
      ALLOWED_ATTR: [],
    })
      .replace(/[<>]/g, "")
      .trim();
  };

  const validateInput = (input) => {
    if (input.length === 0) {
      throw new Error("Input cannot be empty");
    }
    if (input.length < 3) {
      throw new Error("Input is too short");
    }
    if (input.length > 1000) {
      throw new Error("Input is too long");
    }

    if (!/[a-zA-Z0-9]/.test(input)) {
      throw new Error("Input must contain letters or numbers");
    }

    return input;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setIsImageLoaded(false);

    try {
      const sanitizedInput = sanitizeInput(inputMessage);
      const validatedInput = validateInput(sanitizedInput);

      if (mysticoinsRemaining !== null && mysticoinsRemaining > 0) {
        setIsLoading(true);

        try {
          const auth = getAuth();
          const user = auth.currentUser;

          if (!user) {
            throw new Error("No se encontró el usuario autenticado");
          }

          const token = await user.getIdToken();

          const response = await fetch(process.env.REACT_APP_DREAM_BOT_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ question: validatedInput }),
            credentials: "include",
          });

          if (response.ok) {
            const data = await response.json();
            setResponse(data.response);
            if (data.image_url) {
              setBackgroundImage(data.image_url);
              const img = new Image();
              img.onload = () => setIsImageLoaded(true);
              img.src = data.image_url;
            }
            fetchMysticoinsRemaining();
          } else if (response.status === 401) {
            
            setErrorMessage(
              "Tu sesión ha expirado. Por favor, inicia sesión de nuevo."
            );
            navigate("/login"); // Redirige al usuario al inicio de sesión
          } else if (response.status === 403) {
            setIsBuyModalOpen(true);
          } else {
            const errorData = await response.json();
            setErrorMessage(
              errorData.error ||
                "Lo siento, ha ocurrido un error. Por favor, intenta de nuevo."
            );
          }
        } catch (error) {
          
          setErrorMessage(
            "Error de conexión. Por favor, verifica tu conexión a internet e intenta de nuevo."
          );
        }

        setIsLoading(false);
      } else {
        setIsBuyModalOpen(true);
      }
    } catch (error) {
      
      setErrorMessage(
        error.message ||
          "Invalid input detected. Please enter a valid dream description."
      );
    }

    setInputMessage("");
  };

  const handleClear = () => {
    setResponse("");
    setBackgroundImage(null);
    setInputMessage("");
    setErrorMessage("");
    setIsLoading(false);
    setCurrentBackground(null);
    setPreviousBackground(null);

    if (musicPlayerRef.current) {
      musicPlayerRef.current.stopMusic();
    }
  };

  const handleCopyText = () => {
    navigator.clipboard.writeText(response);
  };

  const handleDownloadText = () => {
    const element = document.createElement("a");
    const file = new Blob([response], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "dream_interpretation.txt";
    document.body.appendChild(element);
    element.click();
  };

  const handleDownloadImage = () => {
    const link = document.createElement("a");
    link.href = backgroundImage;
    link.download = "dream_image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const tutorialSteps = [
    {
      title: "Welcome to the Dream Room",
      description:
        "Welcome to the Dream Room, your gateway to exploring dreams, esoteric practices, and personal well-being. Whether you have a question, need guidance, or simply wish to explore, Zephyr and Oniria are here for you.",
    },
    {
      title: "Describe Your Query or Dream",
      description:
        "Enter the details of your dream, question, or any esoteric practice you wish to explore in the input field. No restrictions—this is your space to seek guidance.",
    },
    {
      title: "Receive Insight and Guidance",
      description:
        "Zephyr and Oniria will analyze your input, providing personalized insights and interpretations to help you on your journey.",
    },
    {
      title: "Explore Further",
      description:
        "Ask follow-up questions to delve deeper into your dream, practice, or personal well-being. Zephyr and Oniria are here to support you every step of the way.",
    },
    {
      title: "Using MystiCoins",
      description:
        "Each interpretation or consultation requires MystiCoins. Your current balance is conveniently displayed at the top of the screen.",
    },
    {
      title: "Getting More Mysticoins",
      description:
        "Running low on Mysticoins? Purchase more to continue your exploration of dreams, esoteric practices, and personal well-being.",
    },
  ];

  const handleDeposit = async (amount) => {
    try {
      await fetch(process.env.REACT_APP_DEPOSIT_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ amount }),
        credentials: "include",
      });

      updateMysticoinsRemaining();
      setIsBuyModalOpen(false);
    } catch (error) {
      
    }
  };

  return (
    <div className="dream-room">
      <StarryBackground />
      {isLoading && <Loader />}
      <div className="background-container">
        {previousBackground && (
          <div
            className="background-image previous"
            style={{ backgroundImage: `url(${previousBackground})` }}
          />
        )}
        {currentBackground && (
          <div
            className={`background-image current ${
              isImageLoaded ? "active" : ""
            }`}
            style={{ backgroundImage: `url(${currentBackground})` }}
          />
        )}
      </div>
      <div className="dream-overlay" />

      <header className="dream-header">
        <Link to="/dashboard" className="back-link">
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <FaArrowLeft />
          </motion.div>
        </Link>
        <div className="dream-title-container">
          <motion.h1
            className="dream-title"
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            Dream Room
          </motion.h1>
          <div className="mysticoins-counter-dream">
            <span>
              Mysticoins:{" "}
              {mysticoinsRemaining !== null
                ? mysticoinsRemaining
                : "Loading..."}
            </span>
          </div>
        </div>
        <div className="header-buttons">
          <motion.button
            onClick={() => setIsTutorialOpen(true)}
            className="dream-tutorial-button"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaQuestionCircle />
          </motion.button>
        </div>
      </header>

      <div className="dream-interface">
        {response && (
          <div className="response-container">
            <div className="bot-icon">
              <MusicPlayer ref={musicPlayerRef} />
            </div>
            <ReactMarkdown
              className="dream-bot-response-text"
              components={{
                p: ({ node, ...props }) => (
                  <p className="bot-response-paragraph" {...props} />
                ),
              }}
            >
              {response}
            </ReactMarkdown>
            <div className="response-actions">
              <button onClick={handleCopyText} title="Copy text">
                <FaCopy />
              </button>
              <button onClick={handleDownloadText} title="Download text">
                <FaDownload />
              </button>
              {backgroundImage && (
                <button onClick={handleDownloadImage} title="Download image">
                  <FaDownload />
                </button>
              )}
            </div>
          </div>
        )}

        <form onSubmit={handleSubmit} className="input-form">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Describe your dream or ask for esoteric wisdom..."
            className="dream-input"
            disabled={isLoading}
          />
          <motion.button
            type="submit"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="send-button"
            disabled={isLoading}
          >
            {isLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <FaPaperPlane />
            )}
          </motion.button>
          <motion.button
            onClick={handleClear}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="clear-button"
            type="button"
          >
            <FaTrash />
          </motion.button>
        </form>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>

      <QuickTutorialModal
        isOpen={isTutorialOpen}
        onClose={() => setIsTutorialOpen(false)}
        tutorialSteps={tutorialSteps}
      />

      <BuyMystiCoinsModal
        isOpen={isBuyModalOpen}
        onClose={() => setIsBuyModalOpen(false)}
        onDeposit={handleDeposit}
      />
    </div>
  );
};

export default memo(DreamRoom);
