import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext.js';
import { auth } from '../firebase'; // Importa Firebase auth

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null); // Renombramos para evitar confusión con `user` de AuthContext
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isAuthenticated, isLoading: authLoading } = useAuth();

  // Función para obtener los datos del usuario
  const fetchUserData = useCallback(async (retryCount = 0) => {
    if (!isAuthenticated) {
      setLoading(false);
      setUserData(null);
      return;
    }

    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No se encontró el usuario autenticado');
      }

      // Obtiene el token de ID actual
      const idToken = await currentUser.getIdToken();

      const response = await axios.get(process.env.REACT_APP_USER_STATUS_URL, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      if (!response.data || response.data.mysticoins === undefined) {
        if (retryCount < 5) {
          setTimeout(() => fetchUserData(retryCount + 1), 2000);
        } else {
          throw new Error('No se pudo obtener los datos completos del usuario.');
        }
      } else {
        setUserData(response.data);
        setError(null);
      }

    } catch (error) {
      
      setUserData(null);
      if (error.response && error.response.status === 401) {
        setError('Sesión expirada. Por favor, inicie sesión nuevamente.');
      } else {
        setError('Error al cargar los datos del usuario');
      }
    } finally {
      setLoading(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !authLoading && !userData) {
      fetchUserData();
    }
  }, [isAuthenticated, authLoading, userData, fetchUserData]);

  return (
    <UserContext.Provider value={{ userData, loading, error }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser debe ser usado dentro de un UserProvider');
  }
  return context;
};




