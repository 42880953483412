// src/App.js
// MystiChat AI - Bittech nETWORK 24 lets go to the moon

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { MysticoinsProvider } from './contexts/MysticoinsContext';
import { UserProvider } from './context/UserContext';
import Loader from './components/Loader';
import './App.css';

import Home from './pages/Home';
import SignIn from './components/Signin';
import Dashboard from './pages/Dashboard';
import TarotRoom from './pages/TarotRoom';
import DreamRoom from './pages/DreamRoom';
import Ordinals from './pages/Ordinals';
import TarotModal from './components/TarotModal';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import ScrollToTop from "./components/ScrollToTop.js";

/**
 * Ruta protegida que verifica la autenticación antes de renderizar el componente hijo.
 * Muestra un loader mientras verifica el estado de autenticación.
 * Redirige según la lógica definida (protegido o inverso).
 */
const ProtectedRoute = ({ children, inverse = false }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();

  // Muestra un loader mientras se verifica la autenticación
  if (isLoading) {
    return <Loader />;
  }

  // Si es una ruta protegida y el usuario no está autenticado
  if (!isAuthenticated && !inverse) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  // Si es una ruta inversa y el usuario está autenticado
  if (inverse && isAuthenticated) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  // Permitir acceso al componente hijo
  return children;
};

/**
 * Componente principal de la aplicación.
 * Configura las rutas y los proveedores de contexto.
 */
function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);


  return (
    <Router>
      <AuthProvider>
        <UserProvider>
          <MysticoinsProvider>
            <ScrollToTop />
            <div className="App">
              <TarotModal isOpen={isModalOpen} onClose={closeModal} />
              <Routes>
                {/* Ruta para la página de inicio / Home */}
                <Route 
                  path="/" 
                  element={<Home />} 
                />

                {/* Ruta para SignIn */}
                <Route 
                  path="/signin" 
                  element={
                    <ProtectedRoute inverse>
                      <SignIn />
                    </ProtectedRoute>
                  } 
                />

                {/* Rutas protegidas para usuarios autenticados */}
                <Route 
                  path="/dashboard" 
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/tarot-room" 
                  element={
                    <ProtectedRoute>
                      <TarotRoom openModal={openModal} />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/dream-room" 
                  element={
                    <ProtectedRoute>
                      <DreamRoom />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/ordinals" 
                  element={
                    <ProtectedRoute>
                      <Ordinals />
                    </ProtectedRoute>
                  } 
                />

                {/* Rutas públicas */}
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                {/* Ruta wildcard para redirigir rutas no definidas a / */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </div>
          </MysticoinsProvider>
        </UserProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;






