// frontend/src/assets/tarotCards/index.js

import the_fool from './the_fool.svg';
import the_magician from './the_magician.svg';
import the_high_priestess from './the_high_priestess.svg';
import the_empress from './the_empress.svg';
import the_emperor from './the_emperor.svg';
import the_hierophant from './the_hierophant.svg';
import the_lovers from './the_lovers.svg';
import the_chariot from './the_chariot.svg';
import strength from './the_strength.svg';
import the_hermit from './the_hermit.svg';
import wheel_of_fortune from './wheel_of_fortune.svg';
import justice from './the_justice.svg';
import the_hanged_man from './the_hanged_man.svg';
import death from './the_death.svg';
import temperance from './the_temperance.svg';
import the_devil from './the_devil.svg';
import the_tower from './the_tower.svg';
import the_star from './the_star.svg';
import the_moon from './the_moon.svg';
import the_sun from './the_sun.svg';
import judgment from './judgment.svg';
import the_world from './the_world.svg';

export const tarotCards = {
  'the_fool': the_fool,
  'the_magician': the_magician,
  'the_high_priestess': the_high_priestess,
  'the_empress': the_empress,
  'the_emperor': the_emperor,
  'the_hierophant': the_hierophant,
  'the_lovers': the_lovers,
  'the_chariot': the_chariot,
  'strength': strength,
  'the_hermit': the_hermit,
  'wheel_of_fortune': wheel_of_fortune,
  'justice': justice,
  'the_hanged_man': the_hanged_man,
  'death': death,
  'temperance': temperance,
  'the_devil': the_devil,
  'the_tower': the_tower,
  'the_star': the_star,
  'the_moon': the_moon,
  'the_sun': the_sun,
  'judgment': judgment,
  'the_world': the_world,
};
