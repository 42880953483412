// src/components/Signin.js

import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import "./Signin.css";
import logo from "../assets/images/Skull.svg";
import googleLogo from "../assets/images/google.svg";
import tarotImage from "../assets/images/the_tower_logo.svg";
import StarryBackground from '../components/StarryBackground.js';
import { signInWithPopup, GoogleAuthProvider, setPersistence, browserSessionPersistence } from "firebase/auth";
import { auth } from "../firebase";

const SignIn = () => {
  const { handleLoginSuccess } = useAuth();
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setPersistence(auth, browserSessionPersistence)
      .catch(() => {});
  }, []);
  
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'  // Forzar al usuario a seleccionar una cuenta cada vez
    });

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Manejar el inicio de sesión exitoso
      handleLoginSuccess(user);

      // Ya no es necesario guardar el token en sessionStorage
      // El SDK de Firebase manejará el token internamente
    } catch (error) {
      
    }
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className="signin-page">
      <StarryBackground />
      
      <div className="signin-container">
        <div className="signin-box">
          <div className="left-panel">
            <img src={logo} alt="MystiChat Logo" className="logo-skull-2" />
            <div className="text-intro-signin">
              <h2>Welcome to MystiChat AI</h2>
              <p>
                Unlock the mysteries of your destiny with AI-powered Tarot
                readings, dream analysis and much more.
              </p>
            </div>
            <button className="signInButton" onClick={handleGoogleSignIn}>
              <img src={googleLogo} alt="Google Logo" className="google-logo" />
              <span>Sign In with Google</span>
            </button>
            <div className="footer-text">
              By logging in, you accept our Terms of Service and Privacy Policy.
            </div>
            <div className="policy-links">
              <Link to="/terms-of-service">Terms of Service</Link> | <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
          </div>
          <div className="right-panel">
            <img 
              src={tarotImage} 
              alt="Mystic Tarot" 
              className={`tarot-image ${imageLoaded ? 'fade-in' : ''}`}
              onLoad={handleImageLoad}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SignIn);











