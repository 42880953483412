import React from 'react';
import { motion } from 'framer-motion';
import { FaBitcoin } from 'react-icons/fa';
import { GiCrystalBall } from "react-icons/gi"; 
import gammaLogo from '../assets/images/gamma1.png';
import './TarotModal.css';

const TarotModal = ({ isOpen, onGoToTarotRoom, onGoToOrdinals }) => {
  if (!isOpen) return null;

  return (
    <motion.div 
      className="tarot-modal-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div 
        className="tarot-modal-content"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
      >
        <h2>Choose Your Path</h2>
        <div className="tarot-modal-options">
          <motion.div 
            className="option-card tarot"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onGoToTarotRoom}
          >
            <GiCrystalBall className="option-icon" />
            <h3>Tarot Room</h3>
            <p>Start your awesome AI magical reads, you are here for that</p>
          </motion.div>
          <motion.div 
            className="option-card ordinals"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onGoToOrdinals}
          >
            <FaBitcoin className="option-icon" />
            <h3>NFT Ordinals</h3>
            <p>Ultra-exclusive Collection of our actual 22 Major Arcana Tarot Cards</p>
            <img src={gammaLogo} alt="Available on Gamma" className="gamma-logo-1" />
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default TarotModal;