import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Loader.css';
import StarryBackground from "../components/StarryBackground.js";

const Loader = React.memo(({ 
  overlay = false, 
  starry = true, 
  message = "Loading...",
  color = "#ff5c9a",
  size = "100px"
}) => (
  <AnimatePresence>
    <motion.div
      className={`loader-container ${overlay ? 'loader-overlay' : ''}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {starry && <StarryBackground />}
      <div 
        className="loader" 
        style={{ 
          '--loader-color': color,
          '--loader-size': size
        }} 
        aria-label={message}
      />
      {message && <p className="loader-message">{message}</p>}
    </motion.div>
  </AnimatePresence>
));

Loader.displayName = 'Loader';

export default Loader;

