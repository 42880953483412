import React, { memo, useEffect, Suspense, lazy } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ArrowRight, Gift, Star, Sparkles, Users } from "lucide-react";
import { FaStar, FaClock, FaGlobe, FaRobot, FaGem } from "react-icons/fa";
import { tarotCards } from "../assets/tarotCards";

import "./Home.css";
import "./Home1.css";

import {
  FaCat,
  FaMoon,
  FaUserAstronaut,
  FaBolt,
  FaQuoteLeft,
  FaUserCog,
  FaPalette,
  FaLeaf,
  FaBitcoin,
  FaCrown,
  FaRocket,
  FaMagic,
  FaGift,
  FaChartLine,
} from "react-icons/fa";
import { FlagIcon } from "react-flag-kit";
import { IoFlash } from "react-icons/io5";

// Import de imágenes
import theDeathImg from "../assets/images/the_death.svg";
import theMagicianImg from "../assets/images/the_magician.svg";
import theStarImg from "../assets/images/the_tower.svg";
import art23 from "../assets/images/gato.webp";
import arte25 from "../assets/images/azul.webp";
import imgW6I7 from "../assets/images/art23.webp";
import nekro from "../assets/images/coolq.webp";
import victoriaImg from "../assets/images/victoria.webp";

// Lazy loading de componentes
const Header = lazy(() => import("../components/Header.js"));
const Footer = lazy(() => import("../components/Footer"));
const StarryBackground = lazy(() =>
  import("../components/StarryBackground.js")
);

// Animaciones
const titleVariants = {
  hidden: { opacity: 0, y: 100 },
  visible: { opacity: 1, y: 0 },
};

const iconVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};

const sectionVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const selectedCards = ["the_fool", "the_magician", "the_high_priestess"];

const Home = () => {
  useEffect(() => {
    const loadTwitterWidget = () => {
      if (!window.twttr) {
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        script.async = true;
        script.charset = "utf-8";
        document.body.appendChild(script);

        script.onload = () => {
          if (window.twttr && window.twttr.widgets) {
            window.twttr.widgets.load();
          }
        };
      } else if (window.twttr && window.twttr.widgets) {
        window.twttr.widgets.load();
      }
    };

    loadTwitterWidget();
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="home-container">
        <StarryBackground />
        <Header />
        <header className="home-hero">
          <motion.h1
            className="home-title"
            initial="hidden"
            whileInView="visible"
            variants={titleVariants}
            transition={{ duration: 0.1 }}
          >
            MystiChat
          </motion.h1>
          <motion.p
            className="home-subtitle"
            initial="hidden"
            whileInView="visible"
            variants={titleVariants}
            transition={{ duration: 0.3 }}
          >
            Unveil Your Destiny with AI-Powered Tarot & Dream Analysis
          </motion.p>
          <motion.p
            className="home-description"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            Embark on a transformative journey of self-discovery
          </motion.p>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Link to="/signin" className="sign-in-button">
              Sign In
            </Link>
          </motion.div>
        </header>

        <section className="home-features">
          <motion.div
            className="home-feature home-feature-tarot"
            initial="hidden"
            whileInView="visible"
            variants={iconVariants}
            transition={{ duration: 0.3 }}
          >
            <FaCat className="home-feature-icon" />
            <h3 className="home-feature-title">AI Tarot Readings</h3>
            <p className="home-feature-description">
              Experience tarot like never before with our AI-powered
              interpretations. Our advanced algorithms analyze card combinations
              and their relevance to your life, providing deep, personalized
              insights.
            </p>
          </motion.div>
          <motion.div
            className="home-feature home-feature-dream"
            initial="hidden"
            whileInView="visible"
            variants={iconVariants}
            transition={{ duration: 0.3 }}
          >
            <FaMoon className="home-feature-icon" />
            <h3 className="home-feature-title">Dream Analysis</h3>
            <p className="home-feature-description">
              Unlock the secrets of your subconscious mind. Our AI delves into
              the symbolism of your dreams, helping you understand hidden
              messages and guiding you towards personal growth.
            </p>
          </motion.div>
          <motion.div
            className="home-feature home-feature-growth"
            initial="hidden"
            whileInView="visible"
            variants={iconVariants}
            transition={{ duration: 0.3 }}
          >
            <FaUserAstronaut className="home-feature-icon" />
            <h3 className="home-feature-title">Personal Growth</h3>
            <p className="home-feature-description">
              Embark on a journey of self-discovery and spiritual enlightenment.
              Our AI companion guides you through introspection, helping you
              realize your full potential and achieve inner harmony.
            </p>
          </motion.div>
        </section>

        <section className="home-tarot-showcase">
          <motion.h2
            className="home-section-title"
            initial="hidden"
            whileInView="visible"
            variants={titleVariants}
            transition={{ duration: 0.5 }}
          >
            Explore the Arcana with AI
          </motion.h2>

          <p className="home-section-description">
            Our tarot AI is #1 is the first ever trained using sacred texts
            combined with the science and art of tarot interpretation. This
            unique blend ensures each reading is grounded in ancient wisdom and
            enhanced with scientific precision and artistic flair.
          </p>

          <div
            className="home-section-description"
            style={{ marginTop: "1em" }}
          >
            Forget the inaccurate and boring tarot readings out there—try our AI
            and experience the most incredible tarot system to date.
          </div>

          <section className="home-artist-info">
            <p className="home-artist-description">
              What Makes Our Tarot Truly Unique Our tarot bot is the first model
              ever trained with sacred texts alongside the science and art of
              tarot interpretation. This unparalleled combination ensures that
              every reading is not only rooted in ancient wisdom but also
              enhanced by scientific precision and artistic nuance.
            </p>
          </section>
          <div className="home-card-container">
            {[
              {
                img: theDeathImg,
                name: "Death",
                description:
                  "Embrace transformation, changes and new beginnings",
              },
              {
                img: theMagicianImg,
                name: "The Magician",
                description:
                  "Harness your power to manifest your desires and dreams",
              },
              {
                img: theStarImg,
                name: "The Tower",
                description:
                  "A symbol of revelations, leading to necessary change and transformation.",
              },
            ].map((card, index) => (
              <motion.div
                key={index}
                className="home-card-wrapper"
                initial={{ opacity: 0, rotate: -10, scale: 0.8 }}
                animate={{ opacity: 1, rotate: 0, scale: 1 }}
                transition={{ delay: index * 0.2, duration: 0.8 }}
                whileHover={{ scale: 1.05, rotate: 5, zIndex: 1 }}
              >
                <img
                  src={card.img}
                  alt={card.name}
                  className="home-tarot-card"
                />
                <div className="home-card-info">
                  <h3 className="home-card-title">{card.name}</h3>
                  <p className="home-card-description">{card.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </section>

        <section className="home-features">
          <motion.div
            className="home-feature home-feature-tarot"
            initial="hidden"
            whileInView="visible"
            variants={iconVariants}
            transition={{ duration: 0.3 }}
          >
            <FaStar className="home-feature-icon" />
            <h3 className="home-feature-title">Celestial Guard AI 24/7</h3>
            <p className="home-feature-description">
              Our bot operates around the clock, monitoring the sky and tracking
              planetary movements, eclipses, and lunar phases to enrich each
              reading with real-time astronomical data.
            </p>
          </motion.div>
          <motion.div
            className="home-feature home-feature-dream"
            initial="hidden"
            whileInView="visible"
            variants={iconVariants}
            transition={{ duration: 0.3 }}
          >
            <FaClock className="home-feature-icon" />
            <h3 className="home-feature-title">Planetary Analysis</h3>
            <p className="home-feature-description">
              Meticulous tracking of each planetary movement, from conjunctions
              and oppositions to retrogrades, to deliver deep and accurate
              interpretations that reflect current cosmic energies.
            </p>
          </motion.div>
          <motion.div
            className="home-feature home-feature-growth"
            initial="hidden"
            whileInView="visible"
            variants={iconVariants}
            transition={{ duration: 0.3 }}
          >
            <FaGlobe className="home-feature-icon" />
            <h3 className="home-feature-title">Ancient Tarot Methods</h3>
            <p className="home-feature-description">
              Choose between 10+ of the most practical Tarot methods like:
              Celtic Cross, Relationship, HorseShoe, Single Card, Carreer Path,
              Monthly forecast and much more.
            </p>
          </motion.div>
        </section>

        <section className="home-dream-room">
          <motion.h2
            className="home-section-title"
            initial="hidden"
            whileInView="visible"
            variants={titleVariants}
            transition={{ duration: 0.5 }}
          >
            Enter the Dream Room
          </motion.h2>
          <p className="home-section-description">
            Unlock the mysteries of your subconscious and explore the realms of
            esoteric wisdom with our advanced Dream Room. Powered by
            cutting-edge AI trained on ancient knowledge, our mystical guide
            offers unparalleled insights into your dreams and beyond.
          </p>
          <div className="home-dream-content">
            <div className="home-dream-text">
              <p className="home-dream-experience-highlight">
                PREPARE TO BE AMAZED. Experience the fusion of ancient wisdom
                and AI technology, creating a truly transformative journey
                through your subconscious and spiritual realms.
              </p>
            </div>
            <div className="home-dream-features">
              <motion.div
                className="home-dream-feature"
                initial="hidden"
                whileInView="visible"
                variants={iconVariants}
                transition={{ duration: 0.3 }}
              >
                <FaUserCog className="home-dream-feature-icon" />
                <h3 className="home-dream-feature-title">
                  Dream Interpretation
                </h3>
                <p className="home-dream-feature-description">
                  Receive detailed, personalized interpretations of your dreams,
                  unraveling the symbols hidden in your subconscious.
                </p>
              </motion.div>
              <motion.div
                className="home-dream-feature"
                initial="hidden"
                whileInView="visible"
                variants={iconVariants}
                transition={{ duration: 0.3 }}
              >
                <FaPalette className="home-dream-feature-icon" />
                <h3 className="home-dream-feature-title">
                  AI-Generated Dreamscapes
                </h3>
                <p className="home-dream-feature-description">
                  Watch as your dreams come to life with stunning, AI-created
                  background images, enhancing your mystical experience.
                </p>
              </motion.div>
              <motion.div
                className="home-dream-feature"
                initial="hidden"
                whileInView="visible"
                variants={iconVariants}
                transition={{ duration: 0.3 }}
              >
                <FaLeaf className="home-dream-feature-icon" />
                <h3 className="home-dream-feature-title">Spiritual Growth</h3>
                <p className="home-dream-feature-description">
                  Engage in therapeutic conversations, guided meditations, and
                  personal development insights to nurture your spiritual
                  journey.
                </p>
              </motion.div>
              <motion.div
                className="home-dream-feature"
                initial="hidden"
                whileInView="visible"
                variants={iconVariants}
                transition={{ duration: 0.3 }}
              >
                <FaMagic className="home-dream-feature-icon" />
                <h3 className="home-dream-feature-title">
                  Esoteric Exploration
                </h3>
                <p className="home-dream-feature-description">
                  Dive deep into the mystical world with intelligent
                  consultations on tarot, astrology, and various esoteric
                  practices, expanding your spiritual understanding.
                </p>
              </motion.div>
            </div>
          </div>
        </section>

        <section className="home-ai-art-showcase">
          <motion.h2
            className="home-section-title"
            initial="hidden"
            whileInView="visible"
            variants={titleVariants}
            transition={{ duration: 0.5 }}
          >
            AI-Generated Mystical Art
          </motion.h2>
          <p className="home-section-description">
            Immerse yourself in the visual manifestation of your dreams and
            spiritual journeys. Our AI transforms the essence of your
            experiences into mesmerizing artwork, bridging the gap between the
            conscious and subconscious realms.
          </p>
          <Carousel autoPlay infiniteLoop showStatus={false} showThumbs={false}>
            <div className="home-carousel-slide">
              <img
                src={art23}
                alt="AI Generated Art 1"
                className="home-ai-art"
              />
            </div>
            <div className="home-carousel-slide">
              <img
                src={arte25}
                alt="AI Generated Art 2"
                className="home-ai-art"
              />
            </div>
            <div className="home-carousel-slide">
              <img
                src={imgW6I7}
                alt="AI Generated Art 3"
                className="home-ai-art"
              />
            </div>
            <div className="home-carousel-slide">
              <img
                src={nekro}
                alt="AI Generated Art 4"
                className="home-ai-art"
              />
            </div>
          </Carousel>
        </section>

        <section className="home-testimonials">
          <motion.h2
            className="home-section-title"
            initial="hidden"
            whileInView="visible"
            variants={titleVariants}
            transition={{ duration: 0.5 }}
          >
            Voices of Transformation
          </motion.h2>
          <div className="home-testimonial-grid">
            {[
              {
                name: "Sarah M.",
                text: "MystiChat's tarot readings have been a game-changer for me. The insights I've gained have helped me navigate some of life's toughest decisions with confidence. I never thought AI could play such a profound role in reflection and self-esteem. It's truly amazing.",
                country: "US",
              },
              {
                name: "John D.",
                text: "The dream analysis feature is super cool. It's just crazy, AI rules. Plus, those monthly sats are a nice bonus—at least I'm getting something back from a subscription! I even got it for my girlfriend, and she loves it.",
                country: "GB",
              },
              {
                name: "Emma L.",
                text: "I love how MystiChat combines technology with spiritual practices. It's like having a wise mentor and a futuristic AI rolled into one. Truly a unique and transformative experience!",
                country: "CA",
              },
              {
                name: "Nicole R.",
                text: "Me la dieron a probar y de verdad que me quede enganchadisima, Chat GPT no me responde ni la mitad de lo que encuentro aca. Me encanta y me compré el paquete de 100 usd y me olvide de historias, aparte en Bitcoin. Felicidades al equipo.",
                country: "ES",
              },
            ].map((testimonial, index) => (
              <motion.div
                key={index}
                className="home-testimonial"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <motion.div
                  className="home-quote-icon"
                  initial="hidden"
                  whileInView="visible"
                  variants={iconVariants}
                  transition={{ duration: 0.3 }}
                >
                  <FaQuoteLeft />
                </motion.div>
                <p className="home-testimonial-text">"{testimonial.text}"</p>
                <div className="home-testimonial-footer">
                  <span className="home-testimonial-name">
                    - {testimonial.name}
                  </span>
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    variants={iconVariants}
                    transition={{ duration: 0.3 }}
                  >
                    <FlagIcon
                      code={testimonial.country}
                      size={24}
                      className="home-testimonial-flag"
                    />
                  </motion.div>
                </div>
              </motion.div>
            ))}
          </div>
        </section>

        <section className="home-victoria-showcase">
          <div className="home-victoria-image-container">
            <img
              src={victoriaImg}
              alt="Victoria"
              className="home-victoria-image"
            />
          </div>
          <div className="home-victoria-text"></div>
        </section>

        <section className="home-subscription-section">
          <motion.h2
            className="home-section-title"
            initial="hidden"
            whileInView="visible"
            variants={titleVariants}
            transition={{ duration: 0.5 }}
          >
            Choose Your Path to Enlightenment
          </motion.h2>
          <div className="payment-crypto">
            <p>
              Secure and lightning-fast payments accepted in Bitcoin via
              OpenNode
            </p>
          </div>

          <div className="home-subscription-options">
            <motion.div
              className="home-subscription-option"
              initial="hidden"
              whileInView="visible"
              variants={iconVariants}
              transition={{ duration: 0.3 }}
            >
              <div className="home-subscription-icons">
                <FaBolt className="home-subscription-icon" />
              </div>
              <h3 className="home-subscription-title">Mysti Lightning</h3>
              <p className="intro-pay">
                Quick boost for your mystical journey with Lightning Network!
              </p>
              <p className="home-subscription-price">$4.99</p>
              <ul className="home-subscription-features">
                <li>10 MystiCoins + 5 Bonus MystiCoins</li>
                <li>Lightning Network exclusive</li>
                <li>Instant transactions</li>
                <li>Ultra-low fees</li>
                <li>Perfect for quick boosts</li>
              </ul>
            </motion.div>

            <motion.div
              className="home-subscription-option"
              initial="hidden"
              whileInView="visible"
              variants={iconVariants}
              transition={{ duration: 0.3 }}
            >
              <div className="home-subscription-icons">
                <FaCrown className="home-subscription-icon" />
              </div>
              <h3 className="home-subscription-title">Astral Explorer</h3>
              <p className="intro-pay">
                Embark on your mystical journey with a great pack of MystiCoins!
              </p>
              <p className="home-subscription-price">$19.99</p>
              <ul className="home-subscription-features">
                <li>25 MystiCoins + 15 Bonus MystiCoins</li>
                <li>Access to Tarot and Dream interpretations</li>
                <li>Unlock Astral Explorer status</li>
                <li>Lightning-fast transactions</li>
                <li>Satoshi Back Program</li>
              </ul>
            </motion.div>

            <motion.div
              className="home-subscription-option home-subscription-option-featured"
              initial="hidden"
              whileInView="visible"
              variants={iconVariants}
              transition={{ duration: 0.3 }}
            >
              <div className="home-subscription-icons">
                <FaRocket className="home-subscription-icon" />
              </div>
              <h3 className="home-subscription-title">Ascended Master</h3>
              <p className="intro-pay">
                Elevate your spiritual journey with our premium MystiCoins
                package!
              </p>
              <p className="home-subscription-price">$74.99</p>
              <ul className="home-subscription-features">
                <li>60 MystiCoins + 60 Bonus MystiCoins</li>
                <li>Priority access to new features</li>
                <li>Exclusive Ascended Master content</li>
                <li>Fast-track to higher Mystic Levels + higher rewards</li>
                <li>Instant Lightning Network transactions</li>
                <li>Satoshi Back Program PLUS</li>
              </ul>
            </motion.div>
          </div>

          <div className="home-crypto-accepted">
            <h3 className="home-crypto-title">Our Payment Options</h3>
            <p className="home-crypto-title">
              Get your Mysti Coins instantly on your account via the Lightning
              Network
            </p>
            <div className="home-crypto-icons">
              <motion.div
                className="crypto-option"
                initial="hidden"
                whileInView="visible"
                variants={iconVariants}
                transition={{ duration: 0.3 }}
              >
                <FaBitcoin className="crypto-icon" />
                <h4 className="crypto-option-title">Bitcoin On-chain</h4>
                <p className="crypto-option-description">
                  Traditional transactions
                </p>
              </motion.div>
              <motion.div
                className="crypto-option"
                initial="hidden"
                whileInView="visible"
                variants={iconVariants}
                transition={{ duration: 0.3 }}
              >
                <IoFlash className="lightning-icon" />
                <h4 className="crypto-option-title">Lightning Network</h4>
                <p className="crypto-option-description">
                  Instant, low-fee transactions
                </p>
              </motion.div>
            </div>
          </div>
        </section>

        <section className="home-satoshi-back">
          <motion.h2
            className="home-section-title"
            initial="hidden"
            whileInView="visible"
            variants={titleVariants}
            transition={{ duration: 0.5 }}
          >
            Satoshi Back Program
          </motion.h2>
          <p className="home-satoshi-back-description">
            Experience the Journey and Get Paid! Secure the Astral Explorer and
            Ascend Master packs and begin earning free weekly satoshis through
            our unique system.{" "}
          </p>
          <div className="home-satoshi-back-features">
            <motion.div
              className="home-satoshi-back-feature"
              initial="hidden"
              whileInView="visible"
              variants={iconVariants}
              transition={{ duration: 0.3 }}
            >
              <FaBitcoin className="home-satoshi-back-icon" />
              <h3>Instant Weekly Rewards</h3>
              <p>
                Start receiving satoshis every week directly to your wallet—no
                waiting, just rewards.
              </p>
            </motion.div>
            <motion.div
              className="home-satoshi-back-feature"
              initial="hidden"
              whileInView="visible"
              variants={iconVariants}
              transition={{ duration: 0.3 }}
            >
              <FaGift className="home-satoshi-back-icon" />
              <h3>Your Mystical Level</h3>
              <p>
                Supercharge Your Rewards! Purchasing MystiCoins boosts your
                Mystical Level and maximizes your satoshi earnings
              </p>
            </motion.div>
            <motion.div
              className="home-satoshi-back-feature"
              initial="hidden"
              whileInView="visible"
              variants={iconVariants}
              transition={{ duration: 0.3 }}
            >
              <FaChartLine className="home-satoshi-back-icon" />
              <h3>Amplify Your Bitcoin Growth</h3>
              <p>
                Watch your Bitcoin savings soar over time! Yes, it's real
                Bitcoin—experience genuine growth with us.
              </p>
            </motion.div>
          </div>
        </section>

        <section className="home-ordinals-nft">
          <motion.h2
            className="home-section-title-ordinals"
            initial="hidden"
            whileInView="visible"
            variants={cardVariants}
            transition={{ duration: 0.5 }}
          >
            Ordinals NFTs Built on the Strength of Bitcoin
          </motion.h2>
          <motion.div
            className="ordinals-nft-container"
            initial="hidden"
            whileInView="visible"
            variants={sectionVariants}
          >
            {selectedCards.map((card, index) => (
              <motion.div
                key={card}
                className="ordinal-card"
                variants={cardVariants}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <img src={tarotCards[card]} alt={card.replace("_", " ")} />
                <h3>{card.replace("_", " ").toUpperCase()}</h3>
              </motion.div>
            ))}
          </motion.div>
          <motion.p
            className="ordinals-description"
            initial="hidden"
            whileInView="visible"
            variants={cardVariants}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            Immerse yourself in the mystical world of MystiChat's Tarot
            Ordinals. Each card is a unique digital masterpiece, inscribed on
            the Bitcoin blockchain. Own a piece of mystical history and unlock
            the power of the arcana in the digital age.{" "}
            <p>
              The Tarot Room's 22 iconic designs are as rare as they are
              powerful – claim your piece of history before it's too late!
            </p>
          </motion.p>
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={cardVariants}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <Link to="/ordinals" className="explore-ordinals-button">
              <FaGem /> Explore Ordinals Collection
            </Link>
          </motion.div>
        </section>

        <section className="home-twitter-embed">
          <motion.h2
            className="home-section-title"
            initial="hidden"
            whileInView="visible"
            variants={titleVariants}
            transition={{ duration: 0.5 }}
          >
            Want more? We have more!
          </motion.h2>
          <p className="home-twitter-description">
            We've created another amazing AI model for X: ✨ Compact but mighty
            ✨ Tailored for tweet-sized horoscopes. Daily cosmic insights that
            pack a punch Curious about your celestial forecast? Follow
            @mystichat_ai now! #AIAstrology #DailyHoroscope{" "}
            <p>#CelestialInsights on another level!</p>
          </p>
          <div className="twitter-embed-container">
            <blockquote className="twitter-tweet" data-theme="dark">
              <p lang="en" dir="ltr">
                🌟✨ Sagittarius: Are the stars aligning for a big change? Trust
                your instincts today! That wild idea might just be your ticket
                to success. Love life? Expect the unexpected! 🏹 Remember, your
                optimism is your superpower.{" "}
                <a href="https://twitter.com/hashtag/MystiChat?src=hash&amp;ref_src=twsrc%5Etfw">
                  #MystiChat
                </a>{" "}
                <a href="https://twitter.com/hashtag/SagittariusHoroscope?src=hash&amp;ref_src=twsrc%5Etfw">
                  #SagittariusHoroscope
                </a>
              </p>
              &mdash; MystiChat.ai (@mystichat_ai){" "}
              <a href="https://twitter.com/mystichat_ai/status/1831372692529770666?ref_src=twsrc%5Etfw">
                September 4, 2024
              </a>
            </blockquote>
          </div>
        </section>

        <section className="home-final-cta">
          <motion.h2
            className="home-final-cta-title"
            initial="hidden"
            whileInView="visible"
            variants={titleVariants}
            transition={{ duration: 0.5 }}
          >
            Ready to Unlock Your Mystical Potential?
          </motion.h2>
          <div className="home-final-cta-description">
            <p>
              Join MystiChat today and embark on a journey of self-discovery and
              spiritual growth.
            </p>
            <ul className="cta-features">
              <li>
                <Star className="icon" />
                <span>Login quickly and securely via Google</span>
              </li>
              <li>
                <Gift className="icon" />
                <span>
                  We have <strong className="highlight">FREE</strong> Mysti
                  Coins for you!
                </span>
              </li>
              <li>
                <FaRobot className="icon" />
                <span>Try our expert AI Tarot reader and AI Dream Guru</span>
              </li>
              <li>
                <Sparkles className="icon" />
                <span>
                  Experience the magic that's making MystiChat a favorite among
                  spiritual seekers
                </span>
              </li>
              <li>
                <Users className="icon" />
                <span>
                  Join a community where every member's journey is valued and
                  supported 💖 ₿ ⚡
                </span>
              </li>
            </ul>
          </div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Link to="/signin" className="sign-in-button">
              Begin your Mystical Adventure <ArrowRight className="icon" />
            </Link>
          </motion.div>
        </section>
        <Footer />
      </div>
    </Suspense>
  );
};

export default memo(Home);
