import React, { useState, useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import { FaPlay, FaPause, FaMusic } from 'react-icons/fa';
import "./MusicPlayer.css";

// Importar los archivos de audio
import mysti from '../assets/audio/mysti.mp3';
import mysti1 from '../assets/audio/mysti1.mp3';
import mysti2 from '../assets/audio/mysti2.mp3';
import mysti3 from '../assets/audio/mysti3.mp3';
import mysti4 from '../assets/audio/mysti4.mp3';
import mysti5 from '../assets/audio/mysti5.mp3';
import mysti6 from '../assets/audio/mysti6.mp3';

const allSongs = [mysti, mysti1, mysti2, mysti3, mysti4, mysti5, mysti6];

// Función para mezclar el array
const shuffleArray = (array) => {
  const newArray = [...array];
  for (let i = newArray.length -1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i+1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
};

const MusicPlayer = forwardRef((props, ref) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentSong, setCurrentSong] = useState(null);
  const audioRef = useRef(new Audio());
  const remainingSongsRef = useRef(shuffleArray(allSongs));

  const playRandomSong = useCallback(() => {
    // Si no quedan canciones por reproducir, volvemos a mezclar la lista
    if (remainingSongsRef.current.length === 0) {
      remainingSongsRef.current = shuffleArray(allSongs);
    }

    const newSong = remainingSongsRef.current.pop(); // Toma la última canción del array

    audioRef.current.src = newSong;
    audioRef.current.load();
    audioRef.current.play().catch(err => {
      
    });
    setCurrentSong(newSong);
    setIsPlaying(true);

    // Cuando termina la canción, se pasa a la siguiente aleatoria
    audioRef.current.onended = playRandomSong;
  }, []); // Lista de dependencias vacía

  const togglePlay = useCallback(() => {
    if (!currentSong) {
      playRandomSong();
    } else if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current.play().catch(err => {
        
      });
      setIsPlaying(true);

      // Aseguramos que el evento onended esté actualizado
      audioRef.current.onended = playRandomSong;
    }
  }, [currentSong, isPlaying, playRandomSong]);

  useImperativeHandle(ref, () => ({
    stopMusic: () => {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  }));

  return (
    <button onClick={togglePlay} className="music-player-button">
      {isPlaying ? <FaPause /> : (currentSong ? <FaPlay /> : <FaMusic />)}
    </button>
  );
});

export default MusicPlayer;

