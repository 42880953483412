// src/components/BuyMystiCoinsModal.js
import React, { useState, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaBitcoin, FaCrown, FaRocket, FaBolt } from "react-icons/fa";
import { IoFlash } from "react-icons/io5";
import "./BuyMystiCoinsModal.css";
import { AuthContext } from "../contexts/AuthContext"; // Asegúrate de importar AuthContext correctamente

const BuyMystiCoinsModal = ({ isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user, isAuthenticated, isLoading: isAuthLoading } = useContext(AuthContext);

  const handleBuyCoins = async (packageType) => {
    setIsLoading(true);
    setError(null);

    // Verificar si el estado de autenticación aún está cargando
    if (isAuthLoading) {
      setError('Por favor, espera un momento.');
      setIsLoading(false);
      return;
    }

    // Verificar si el usuario está autenticado
    if (!isAuthenticated || !user) {
      setError('Usuario no autenticado.');
      setIsLoading(false);
      return;
    }

    try {
      // Obtener el token desde Firebase Auth
      const token = await user.getIdToken();

      const response = await fetch(
        process.env.REACT_APP_PAYMENT_CHECKOUT_URL,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Usar el token obtenido de Firebase
          },
          body: JSON.stringify({
            product: packageType,
            uid: user.uid, // Asegurarse de que el uid está presente
          }),
          credentials: "include",
        }
      );


      const responseData = await response.text();

      if (response.ok) {
        try {
          const data = JSON.parse(responseData);
          if (data.url) {
            window.location.href = data.url;
          } else {
            throw new Error("No URL en la respuesta");
          }
        } catch (parseError) {
          throw new Error("Respuesta inválida del servidor");
        }
      } else {
        let errorMessage;
        try {
          const errorData = JSON.parse(responseData);
          errorMessage = errorData.error || "Error creando checkout";
        } catch (parseError) {
          errorMessage = "Ocurrió un error desconocido";
        }
        throw new Error(errorMessage);
      }
    } catch (error) {
      setError(error.message || "Ocurrió un error. Por favor, intenta de nuevo.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.3 } },
    exit: { opacity: 0, transition: { duration: 0.3 } },
  };

  const modalVariants = {
    hidden: {
      scale: 0.8,
      opacity: 0,
      y: -50,
    },
    visible: {
      scale: 1,
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
        duration: 0.4,
      },
    },
    exit: {
      scale: 0.8,
      opacity: 0,
      y: -50,
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="buymysticoins-modal-overlay"
          variants={overlayVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <motion.div
            className="buymysticoins-modal-content"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <h2 className="buymysticoins-modal-title">Purchase MystiCoins</h2>
            <p className="buymysticoins-modal-message">
              Need more spins? Choose your preferred MystiCoins package and
              continue your mystical journey. For the best experience, we recommend using
              Lightning Network for instant credits and minimal fees.
            </p>
            {error && <p className="buymysticoins-modal-error">{error}</p>}
            <div className="buymysticoins-modal-options">
              {/* Paquete Mysti Lightning */}
              <div className="buymysticoins-modal-option">
                <FaBolt className="buymysticoins-modal-plan-icon" />
                <h3 className="buymysticoins-modal-option-title">
                  Mysti Lightning
                </h3>
                <p className="buymysticoins-modal-option-price">$4.99</p>
                <ul className="buymysticoins-modal-option-features">
                  <li>10 MystiCoins + 5 Bonus MystiCoins</li>
                  <li>Lightning Network exclusive</li>
                  <li>Instant transactions</li>
                  <li>Ultra-low fees</li>
                  <li>Perfect for quick boosts</li>
                  <li>Choose Lightning option at checkout</li>
                </ul>
                <div className="buymysticoins-modal-payment-options">
                  <IoFlash
                    className="buymysticoins-modal-payment-icon lightning-icon"
                    title="Lightning Network Only"
                  />
                </div>
                <button
                  className="buymysticoins-modal-subscribe-button"
                  onClick={() => handleBuyCoins("mysti_lightning")}
                  disabled={isLoading}
                >
                  {isLoading ? "Processing..." : "Buy Mysti Lightning"}
                </button>
              </div>
  
              {/* Paquete Astral Explorer */}
              <div className="buymysticoins-modal-option">
                <FaCrown className="buymysticoins-modal-plan-icon" />
                <h3 className="buymysticoins-modal-option-title">
                  Astral Explorer
                </h3>
                <p className="buymysticoins-modal-option-price">$19.99</p>
                <ul className="buymysticoins-modal-option-features">
                  <li>25 MystiCoins + 15 Bonus MystiCoins</li>
                  <li>Access to Tarot and Dream interpretations</li>
                  <li>Unlock Astral Explorer status</li>
                  <li>Bitcoin payment options</li>
                  <li>Satoshi Back Program</li>
                </ul>
                <div className="buymysticoins-modal-payment-options">
                  <FaBitcoin
                    className="buymysticoins-modal-payment-icon"
                    title="Bitcoin on-chain"
                  />
                  <IoFlash
                    className="buymysticoins-modal-payment-icon lightning-icon"
                    title="Lightning Network"
                  />
                </div>
                <button
                  className="buymysticoins-modal-subscribe-button"
                  onClick={() => handleBuyCoins("astral_explorer")}
                  disabled={isLoading}
                >
                  {isLoading ? "Processing..." : "Buy Astral Explorer"}
                </button>
              </div>
  
              {/* Paquete Ascended Master */}
              <div className="buymysticoins-modal-option buymysticoins-modal-option-featured">
                <FaRocket className="buymysticoins-modal-plan-icon" />
                <h3 className="buymysticoins-modal-option-title">
                  Ascended Master
                </h3>
                <p className="buymysticoins-modal-option-price">$74.99</p>
                <ul className="buymysticoins-modal-option-features">
                  <li>60 MystiCoins + 60 Bonus MystiCoins</li>
                  <li>Priority access to new features</li>
                  <li>Exclusive Ascended Master content</li>
                  <li>Fast-track to higher Mystic Levels + higher rewards</li>
                  <li>Instant Lightning Network transactions</li>
                  <li>Satoshi Back Program PLUS</li>
                </ul>
                <div className="buymysticoins-modal-payment-options">
                  <FaBitcoin
                    className="buymysticoins-modal-payment-icon"
                    title="Bitcoin on-chain"
                  />
                  <IoFlash
                    className="buymysticoins-modal-payment-icon lightning-icon recommended"
                    title="Lightning Network (Recommended)"
                  />
                </div>
                <button
                  className="buymysticoins-modal-subscribe-button"
                  onClick={() => handleBuyCoins("ascended_master")}
                  disabled={isLoading}
                >
                  {isLoading ? "Processing..." : "Buy Ascended Master"}
                </button>
              </div>
            </div>
            <button
              className="buymysticoins-modal-close-button"
              onClick={handleClose}
              disabled={isLoading}
            >
              Close
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
  
};

export default BuyMystiCoinsModal;



