import React, { useState, useEffect, useMemo } from 'react';

const InspirationalQuotes = () => {
    // Memoizamos el array de quotes para que no cambie en cada render
    const quotes = useMemo(() => [
        { text: "Magic is believing in yourself. If you can do that, you can make anything happen.", author: "Johann Wolfgang von Goethe" },
        { text: "The unexamined life is not worth living.", author: "Socrates" },
        { text: "Know thyself and you will know the universe and the gods.", author: "Pythagoras" },
        { text: "It’s not what happens to you, but how you react to it that matters.", author: "Epictetus" },
        { text: "All that we are is the result of what we have thought.", author: "Buddha" },
        { text: "The universe conspires in favor of dreamers.", author: "Paulo Coelho" },
        { text: "Man is a microcosm of the universe.", author: "Giordano Bruno" },
        { text: "There are no facts, only interpretations.", author: "Friedrich Nietzsche" },
        { text: "Those who don't believe in magic will never find it.", author: "Roald Dahl" },
        { text: "The essence of life is to find something worth fighting for.", author: "Socrates" },
        { text: "Science is a way of thinking much more than it is a body of knowledge.", author: "Carl Sagan" },
        { text: "The universe is under no obligation to make sense to you.", author: "Neil deGrasse Tyson" },
        { text: "Do not go where the path may lead, go instead where there is no path and leave a trail.", author: "Ralph Waldo Emerson" },
        { text: "I think, therefore I am.", author: "René Descartes" },
        { text: "Nature does not hurry, yet everything is accomplished.", author: "Lao Tzu" },
        { text: "We are what we repeatedly do. Excellence, then, is not an act, but a habit.", author: "Aristotle" },
        { text: "You must be the change you wish to see in the world.", author: "Mahatma Gandhi" },
        { text: "The only true wisdom is in knowing you know nothing.", author: "Socrates" },
        { text: "To know the secrets of the universe, think in terms of energy, frequency, and vibration.", author: "Nikola Tesla" },
        { text: "Imagination is more important than knowledge.", author: "Albert Einstein" },
        { text: "Not all those who wander are lost.", author: "J.R.R. Tolkien" },
        { text: "The greatest wealth is to live content with little.", author: "Plato" },
        { text: "Happiness depends upon ourselves.", author: "Aristotle" },
        { text: "Believe you can and you're halfway there.", author: "Theodore Roosevelt" },
        { text: "The mind is everything. What you think, you become.", author: "Buddha" },
        { text: "In the middle of difficulty lies opportunity.", author: "Albert Einstein" },
        { text: "Man is the measure of all things.", author: "Protagoras" },
        { text: "All is number.", author: "Pythagoras" },
        { text: "We shape our buildings; thereafter, they shape us.", author: "Winston Churchill" },
        { text: "The only way to do great work is to love what you do.", author: "Steve Jobs" },
        { text: "Without music, life would be a mistake.", author: "Friedrich Nietzsche" },
        { text: "He who has a why to live can bear almost any how.", author: "Friedrich Nietzsche" },
        { text: "It does not matter how slowly you go as long as you do not stop.", author: "Confucius" },
        { text: "Success is not final, failure is not fatal: It is the courage to continue that counts.", author: "Winston Churchill" },
        { text: "What we think, we become.", author: "Buddha" },
        { text: "The best way to predict the future is to invent it.", author: "Alan Kay" },
        { text: "The purpose of life is a life of purpose.", author: "Robert Byrne" },
        { text: "Your time is limited, don't waste it living someone else's life.", author: "Steve Jobs" },
        { text: "The mind is everything. What you think, you become.", author: "Buddha" },
        { text: "Don't count the days, make the days count.", author: "Muhammad Ali" },
        { text: "What lies behind us and what lies before us are tiny matters compared to what lies within us.", author: "Ralph Waldo Emerson" },
        { text: "It always seems impossible until it’s done.", author: "Nelson Mandela" },
        { text: "To be yourself in a world that is constantly trying to make you something else is the greatest accomplishment.", author: "Ralph Waldo Emerson" },
        { text: "Do not wait to strike till the iron is hot; but make it hot by striking.", author: "William Butler Yeats" },
        { text: "Whether you think you can, or you think you can’t – you’re right.", author: "Henry Ford" },
        { text: "There is no substitute for hard work.", author: "Thomas Edison" },
        { text: "What you get by achieving your goals is not as important as what you become by achieving your goals.", author: "Zig Ziglar" },
        { text: "Difficulties mastered are opportunities won.", author: "Winston Churchill" },
        { text: "The future belongs to those who believe in the beauty of their dreams.", author: "Eleanor Roosevelt" },
        { text: "Do not let what you cannot do interfere with what you can do.", author: "John Wooden" },
        { text: "Everything you’ve ever wanted is on the other side of fear.", author: "George Addair" },
        { text: "You miss 100% of the shots you don’t take.", author: "Wayne Gretzky" },
        { text: "I have not failed. I've just found 10,000 ways that won't work.", author: "Thomas Edison" },
        { text: "A journey of a thousand miles begins with a single step.", author: "Lao Tzu" },
        { text: "What we achieve inwardly will change outer reality.", author: "Plutarch" },
        { text: "Failure is the condiment that gives success its flavor.", author: "Truman Capote" },
        { text: "Action is the foundational key to all success.", author: "Pablo Picasso" },
        { text: "An unexamined life is not worth living.", author: "Socrates" },
        { text: "To live is the rarest thing in the world. Most people exist, that is all.", author: "Oscar Wilde" },
        { text: "Success usually comes to those who are too busy to be looking for it.", author: "Henry David Thoreau" },
        { text: "The only limit to our realization of tomorrow is our doubts of today.", author: "Franklin D. Roosevelt" },
        { text: "Creativity is intelligence having fun.", author: "Albert Einstein" },
        { text: "Opportunities don't happen, you create them.", author: "Chris Grosser" },
        { text: "Success is not how high you have climbed, but how you make a positive difference to the world.", author: "Roy T. Bennett" },
        { text: "The pessimist sees difficulty in every opportunity. The optimist sees opportunity in every difficulty.", author: "Winston Churchill" },
        { text: "Happiness is not something ready made. It comes from your own actions.", author: "Dalai Lama" },
        { text: "Do one thing every day that scares you.", author: "Eleanor Roosevelt" },
        { text: "Hardships often prepare ordinary people for an extraordinary destiny.", author: "C.S. Lewis" },
        { text: "The only place where success comes before work is in the dictionary.", author: "Vidal Sassoon" },
        { text: "Success is not in what you have, but who you are.", author: "Bo Bennett" },
        { text: "Your limitation—it’s only your imagination.", author: "Unknown" },
        { text: "Success is not the key to happiness. Happiness is the key to success.", author: "Albert Schweitzer" },
        { text: "The best revenge is massive success.", author: "Frank Sinatra" },
        { text: "Dream big and dare to fail.", author: "Norman Vaughan" },
        { text: "Success is walking from failure to failure with no loss of enthusiasm.", author: "Winston Churchill" },
    ], []); // El array de dependencias vacío asegura que solo se calcule una vez
      

      const [quote, setQuote] = useState(() => {
        const savedQuote = sessionStorage.getItem('dailyQuote');
        return savedQuote ? JSON.parse(savedQuote) : null;
      });
    
      useEffect(() => {
        if (!quote) {
          const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
          setQuote(randomQuote);
          sessionStorage.setItem('dailyQuote', JSON.stringify(randomQuote));
        }
      }, [quote, quotes]); // Mantenemos 'quotes' como dependencia ya que está memoizado
    
      return (
        <div>
          {quote ? (
            <p>
              "{quote.text}" - <strong>{quote.author}</strong>
            </p>
          ) : (
            <p>Loading quote...</p>
          )}
        </div>
      );
    };
    
    export default InspirationalQuotes;
