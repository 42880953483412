import React, { useEffect, useState, Suspense, lazy } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import { FaQuoteLeft, FaCrown, FaSignOutAlt, FaCoins } from "react-icons/fa";
import { GiCrystalBall, GiDreamCatcher, GiCardRandom } from "react-icons/gi";
import { BsClockFill } from "react-icons/bs";
import "../components/WhatsHotCarousel.css";
import "./Dashboard.css";
import StarryBackground from "../components/StarryBackground.js";
import { useUser } from "../context/UserContext.js"; // Usar el hook personalizado
import { useMysticoins } from "../contexts/MysticoinsContext";
import { useAuth } from "../contexts/AuthContext.js";
import { getAuth } from "firebase/auth"; // Importa Firebase Auth
import Loader from "../components/Loader"; // Importamos tu loader custom
import InspirationalQuotes from "../components/InspirationalQuotes.js"; // Importa el componente de citas
import TarotModal from "../components/TarotModal"; // Importar el nuevo componente TarotModal

// Lazy load components
const BuyMystiCoinsModal = lazy(() =>
  import("../components/BuyMystiCoinsModal")
);
const MoonPhase = lazy(() => import("../components/MoonPhase"));
const WhatsHotCarousel = lazy(() => import("../components/WhatsHotCarousel"));

const Dashboard = () => {
  const { userData, loading: userLoading, error: userError } = useUser(); // Usar useUser
  const {
    mysticoinsRemaining,
    mysticLevel,
    updateMysticoinsRemaining,
    clearMysticoinsRemaining,
  } = useMysticoins();

  const [cardOfDay, setCardOfDay] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const navigate = useNavigate();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { handleLogout } = useAuth(); // Viene directamente desde el AuthContext
  const [loading, setLoading] = useState(true); // Añadimos un estado de carga
  const [isTarotModalOpen, setIsTarotModalOpen] = useState(false); // Nuevo estado para el modal de Tarot

  const handleTarotRoomClick = () => {
    setIsTarotModalOpen(true); // Abrir el modal en lugar de navegar directamente
  };

  // Obtenemos la instancia de Firebase Auth
  const auth = getAuth();

  useEffect(() => {
    const tarotCards = [
      "The Fool",
      "The Magician",
      "The High Priestess",
      "The Empress",
      "The Emperor",
      "The Hierophant",
      "The Lovers",
      "The Chariot",
      "Strength",
      "The Hermit",
      "The Wheel of Fortune",
      "Justice",
      "The Hanged Man",
      "Death",
      "Temperance",
      "The Devil",
      "The Tower",
      "The Star",
      "The Moon",
      "The Sun",
      "Judgement",
      "The World",
    ];

    const storedCard = localStorage.getItem("cardOfDay");

    if (storedCard) {
      setCardOfDay(storedCard);
    } else {
      const randomCard =
        tarotCards[Math.floor(Math.random() * tarotCards.length)];
      setCardOfDay(randomCard);
      localStorage.setItem("cardOfDay", randomCard);
    }
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      const cetTime = new Date().toLocaleString("en-GB", {
        timeZone: "Europe/Berlin",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      setCurrentTime(cetTime);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    // Si los MystiCoins no se han cargado correctamente, forzamos la actualización
    if (mysticoinsRemaining === 0) {
      updateMysticoinsRemaining(); // Forzar la actualización al montar
    }

    // Una vez que se actualicen los MystiCoins, detenemos el loader
    setLoading(false);
  }, [mysticoinsRemaining, updateMysticoinsRemaining]);

  if (loading || userLoading) {
    return <Loader message="Welcome My Dear!" />; // Mostrar loader mientras se actualizan los MystiCoins y se carga el usuario
  }

  if (userError) {
    return <p>{userError}</p>;
  }

  const handleUserLogout = async () => {
    setIsLoggingOut(true); // Inicia el estado de logout

    // Simulamos un retraso de 1 segundo antes de ejecutar el logout
    setTimeout(async () => {
      // Limpiar sessionStorage manualmente
      sessionStorage.clear(); // Limpia todos los datos del sessionStorage

      // Limpia los datos del contexto de Mysticoins o cualquier otro dato
      clearMysticoinsRemaining(); // Esta función limpia Mysticoins y otros datos del contexto

      // Ejecuta el proceso de logout en Firebase
      await handleLogout();

      // Restablece el estado de logout
      setIsLoggingOut(false);

      // Redirigir al home después del logout
      navigate("/");
    }, 1000); // Simula un retraso de 1 segundo
  };

  if (isLoggingOut) {
    return <Loader message="Bye Bye my love..." />; // Mostramos el loader mientras se completa el proceso de logout
  }

  const navigateToProtectedRoute = async (route) => {
    try {
      // Obtener el token antes de navegar
      const token = await auth.currentUser.getIdToken();

      // Incluir el token en los headers de las solicitudes si es necesario
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      // Redirigir a la ruta protegida
      navigate(route);
    } catch (error) {
      navigate("/signin");
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12,
      },
    },
  };

  return (
    <div className="dashboard">
      <StarryBackground />

      <motion.h1
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <span>Welcome,</span>
        <span>{userData?.name || "Usuario"}!</span>
      </motion.h1>

      <motion.div
        className="dashboard-grid"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div className="dashboard-card" variants={itemVariants}>
          <Suspense fallback={<div>Loading MoonPhase...</div>}>
            <MoonPhase />
          </Suspense>
        </motion.div>

        <motion.div className="dashboard-card" variants={itemVariants}>
          <FaQuoteLeft className="quote-icon" />
          {/* Usamos el componente InspirationalQuotes */}
          <InspirationalQuotes />
        </motion.div>

        <motion.div className="dashboard-card" variants={itemVariants}>
          <h2>Card of the Day</h2>
          <GiCardRandom className="card-icon" />
          <p>{cardOfDay}</p>
        </motion.div>

        <motion.div className="dashboard-card" variants={itemVariants}>
          <h2>Cosmic Time</h2>
          <BsClockFill className="clock-icon" />
          <p>{currentTime}</p>
        </motion.div>

        <motion.div
          className="dashboard-card mystic-status-card"
          variants={itemVariants}
        >
          <h2
            className="mystic-buy-text"
            onClick={() => setIsBuyModalOpen(true)}
          >
            Buy MystiCoins
          </h2>
          <div className="mystic-status-content">
            <div className="mystic-status-item">
              <FaCoins className="mystic-status-icon mystic-status-icon-coins" />
              <div className="mystic-status-text-container">
                <p className="mystic-status-label">MystiCoins</p>
                <p className="mystic-status-value mystic-status-value-coins">
                  {mysticoinsRemaining}
                </p>
              </div>
            </div>
            <div className="mystic-status-item">
              <FaCrown className="mystic-status-icon mystic-status-icon-crown" />
              <div className="mystic-status-text-container">
                <p className="mystic-status-label">Mystic Level</p>
                <p className="mystic-status-value mystic-status-value-level">
                  {mysticLevel}
                </p>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div className="dashboard-card" variants={itemVariants}>
          <button className="nav-button" onClick={handleTarotRoomClick}>
            <GiCrystalBall className="tarot-icon" />
            <span>Tarot Room</span>
          </button>
        </motion.div>

        <TarotModal
          isOpen={isTarotModalOpen}
          onClose={() => setIsTarotModalOpen(false)}
          onGoToTarotRoom={() => navigateToProtectedRoute("/tarot-room")}
          onGoToOrdinals={() => navigateToProtectedRoute("/ordinals")}
        />

        <motion.div className="dashboard-card" variants={itemVariants}>
          <button
            className="nav-button"
            onClick={() => navigateToProtectedRoute("/dream-room")}
          >
            <GiDreamCatcher className="dream-icon" />
            <span>Dream Room</span>
          </button>
        </motion.div>

        <motion.div
          className="dashboard-card carousel-card"
          variants={itemVariants}
        >
          <Suspense fallback={<div>Loading Carousel...</div>}>
            <WhatsHotCarousel />
          </Suspense>
        </motion.div>

        <motion.div className="dashboard-card" variants={itemVariants}>
          <button className="nav-button" onClick={handleUserLogout}>
            <FaSignOutAlt className="logout-icon" />
            <span>Logout</span>
          </button>
        </motion.div>
      </motion.div>

      <Suspense fallback={<div></div>}>
        <BuyMystiCoinsModal
          isOpen={isBuyModalOpen}
          onClose={() => setIsBuyModalOpen(false)}
        />
      </Suspense>
    </div>
  );
};

export default Dashboard;
