import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTimes, FaMagic, FaLightbulb, FaRocket, FaQuestion } from 'react-icons/fa';
import './QuickTutorialModal.css';

const QuickTutorialModal = ({ isOpen, onClose, tutorialSteps }) => {
  const getStepIcon = (index) => {
    const icons = [FaLightbulb, FaRocket, FaQuestion];
    return icons[index % icons.length];
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="modal-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <motion.div
            className="modal-content"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -20, opacity: 0 }}
            transition={{ type: 'spring', stiffness: 200, damping: 20, duration: 0.3 }}
          >
            <button className="close-button" onClick={onClose}>
              <FaTimes />
            </button>
            <h2><FaMagic className="magic-icon" /> Quick tutorial</h2>
            <div className="tutorial-steps">
              {tutorialSteps.map((step, index) => {
                const StepIcon = getStepIcon(index);
                return (
                  <motion.div
                    key={index}
                    className="tutorial-step"
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.2, delay: index * 0.1 }}
                  >
                    <h3><StepIcon className="step-icon" /> {step.title}</h3>
                    <p>{step.description}</p>
                  </motion.div>
                );
              })}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default QuickTutorialModal;
