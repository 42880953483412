import React from "react";
import "./LegalDocuments.css";

const TermsOfService = () => {
  return (
    <div className="legal-container">
      <h1 className="legal-heading">
        BitTech Network - MystiChat Terms of Service
      </h1>

      <p className="legal-date">Last Updated: [30/8/24]</p>

      <p className="legal-paragraph legal-emphasis">
        PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY ACCESSING OR USING
        MYSTICHAT, YOU AGREE TO BE BOUND BY THESE TERMS OF SERVICE AND ALL TERMS
        INCORPORATED BY REFERENCE.
      </p>

      <h2 className="legal-subheading">1. Acceptance of Terms</h2>
      <p className="legal-paragraph">
        1.1. These Terms of Service ("Terms") govern your access to and use of
        the MystiChat application ("MystiChat" or the "Service") provided by
        BitTech Network ("we," "us," or "our").
      </p>
      <p className="legal-paragraph">
        1.2. By accessing or using MystiChat, you agree to be bound by these
        Terms. If you do not agree to all the terms and conditions of this
        agreement, you may not use or access the Service.
      </p>

      <h2 className="legal-subheading">2. Description of Service</h2>
      <p className="legal-paragraph">
        2.1. MystiChat is an entertainment application that uses artificial
        intelligence to provide tarot readings and dream interpretations. The
        content generated by our AI is for entertainment purposes only and
        should not be taken as professional advice, diagnosis, or treatment.
      </p>

      <h2 className="legal-subheading">3. Eligibility and User Accounts</h2>
      <p className="legal-paragraph">
        3.1. You must be at least 18 years old to use the Service. By agreeing
        to these Terms, you represent and warrant that you are at least 18 years
        old.
      </p>
      <p className="legal-paragraph">
        3.2. To access certain features of the Service, you must create an
        account. We use Google authentication for account creation and login.
      </p>

      <h2 className="legal-subheading">4. MystiCoins and Payments</h2>
      <p className="legal-paragraph">
        4.1. MystiChat operates on a credit system using "MystiCoins". These credits can be purchased through our platform and are used to access various features and services within the application.
      </p>
      <p className="legal-paragraph">
        4.2. All payments for MystiCoins are processed using cryptocurrency, specifically Bitcoin (BTC), Ethereum (ETH), and Tether (USDT).
      </p>
      <p className="legal-paragraph">
        4.3. We use third-party payment services, such as NOWPayments, to process all cryptocurrency transactions. By using these payment services, you agree to comply with their terms of service and privacy policies.
      </p>
      <p className="legal-paragraph">
        4.4. MystiCoins are non-refundable and non-transferable. The number of MystiCoins required for each service may vary and is subject to change at our discretion.
      </p>

      <h2 className="legal-subheading">5. DeFi Operations</h2>
      <p className="legal-paragraph">
        5.1. As a DeFi-embracing platform, our operations are based on blockchain technology and smart contracts. This means that certain aspects of our service, including transactions and data storage, may be decentralized and immutable.
      </p>
      <p className="legal-paragraph">
        5.2. By using our service, you acknowledge and accept the risks and benefits associated with DeFi, including but not limited to: potential smart contract vulnerabilities, blockchain network congestion, and the irreversible nature of blockchain transactions.
      </p>
      <p className="legal-paragraph">
        5.3. We strive to provide a secure and efficient service, but we cannot guarantee against all potential risks inherent in DeFi systems. Users are encouraged to educate themselves about DeFi principles and best practices.
      </p>

      <h2 className="legal-subheading">
        5. User Conduct and Prohibited Activities
      </h2>
      <p className="legal-paragraph">
        5.1. You agree to use MystiChat only for lawful purposes and in
        accordance with these Terms.
      </p>
      <p className="legal-paragraph">5.2. You agree not to use MystiChat:</p>
      <ul className="legal-list">
        <li className="legal-list-item">
          In any way that violates any applicable federal, state, local, or
          international law or regulation.
        </li>
        <li className="legal-list-item">
          To transmit, or procure the sending of, any advertising or promotional
          material, including any "junk mail," "chain letter," "spam," or any
          other similar solicitation.
        </li>
        <li className="legal-list-item">
          To impersonate or attempt to impersonate BitTech Network, a BitTech
          Network employee, another user, or any other person or entity.
        </li>
      </ul>

      <h2 className="legal-subheading">6. Intellectual Property Rights</h2>
      <p className="legal-paragraph">
        6.1. The Service and its entire contents, features, and functionality
        are owned by BitTech Network, its licensors, or other providers of such
        material and are protected by United States and international copyright,
        trademark, patent, trade secret, and other intellectual property or
        proprietary rights laws.
      </p>

      <h2 className="legal-subheading">7. Disclaimer of Warranties</h2>
      <p className="legal-paragraph">
        7.1. YOUR USE OF MYSTICHAT, ITS CONTENT, AND ANY SERVICES OR ITEMS
        OBTAINED THROUGH THE SERVICE IS AT YOUR OWN RISK. THE SERVICE, ITS
        CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE ARE
        PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES
        OF ANY KIND, EITHER EXPRESS OR IMPLIED.
      </p>

      <h2 className="legal-subheading">8. Limitation of Liability</h2>
      <p className="legal-paragraph">
        8.1. IN NO EVENT WILL BITTECH NETWORK, ITS AFFILIATES, OR THEIR
        LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS
        BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT
        OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICE.
      </p>

      <div className="legal-contact">
        <h3>Contact Information</h3>
        <p>
          If you have any questions about these Terms, please contact us at
          networkbittech@gmail.com
        </p>
      </div>

      <p className="legal-footer">
        BY USING MYSTICHAT, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF
        SERVICE, UNDERSTAND THEM, AND AGREE TO BE BOUND BY THEM.
      </p>
    </div>
  );
};

export default TermsOfService;
